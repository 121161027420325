import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';
import { FiDownload, FiMail, FiClock } from "react-icons/fi";
import { AiOutlineGlobal } from "react-icons/ai";
import { BsPersonCircle, BsBriefcaseFill, BsFillTagFill, BsBuilding } from "react-icons/bs";
import { IoSchoolOutline } from "react-icons/io5";
import Footer from "../components/footer";
import ScrollTop from '../components/scrollTop';
import CustomNavbar from "./customnavbar";
import { db, auth } from "../firebase";

export default function CandidateProfile() {
    const { email } = useParams();
    const [profileData, setProfileData] = useState({
        name: '',
        email: '',
        gender: '',
        country: '',
        timezone: '',
        currentJobTitle: '',
        workExperience: [],
        certifications: [],
        cvUrl: '',
        videoIntroUrl: '',
        profileImage: '',
        hourlyRate: '',
        industryCategory: '',
        linkedin: '',
        primarySkills: []
    });
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, firebaseUser => {
            if (!firebaseUser) {
                navigate('/login');
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        const fetchProfileData = async () => {
            const docRef = collection(db, "profiles");
            const snapshot = await getDocs(docRef);
            const data = snapshot.docs.map(doc => doc.data()).find(profile => profile.email === email);
            if (data) {
                setProfileData({
                    ...profileData,
                    ...data,
                    workExperience: Array.isArray(data.workExperience) ? data.workExperience : [],
                    certifications: Array.isArray(data.certifications) ? data.certifications : [],
                    primarySkills: Array.isArray(data.primarySkills) ? data.primarySkills : []
                });
            }
        };

        fetchProfileData();
    }, [email]);

    const removeHtmlTags = (text) => text.replace(/<\/?[^>]+(>|$)/g, "");

    const cardStyle = {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(10, 88, 202, 0.2)',
        marginBottom: '25px',
        border: '1px solid #ccc',
        transition: 'transform 0.3s ease-in-out',
    };

    const iconStyle = {
        color: 'rgb(10, 88, 202)',
        marginRight: '10px',
    };

    const skillStyle = {
        display: 'inline-block',
        padding: '8px 12px',
        backgroundColor: '#007bff',
        color: '#fff',
        borderRadius: '20px',
        margin: '5px',
        fontSize: '14px',
        fontWeight: '500',
    };

    return (
        <>
            <CustomNavbar />
            <div className="container" style={{ maxWidth: '1200px', margin: 'auto', padding: '20px' }}>
                <div className="row">
                    <div className="col-md-4 text-center" style={{ marginBottom: '20px' }}>
                        <img src={profileData.profileImage || 'https://via.placeholder.com/150'} alt="Profile" style={{ width: '250px', height: '250px', borderRadius: '50%', objectFit: 'cover' }} />
                        <h3 style={{ marginTop: '20px' }}>{profileData.name || 'Unavailable'}</h3>
                        <p style={{ color: '#666', marginBottom: '20px' }}>{profileData.currentJobTitle || 'No Job Title'}</p>
                        {profileData.primarySkills.length > 0 && (
                            <div style={{ marginBottom: '20px' }}>
                                <h5 style={{ color: '#007bff' }}>Skills</h5>
                                <div>
                                    {profileData.primarySkills.map((skill, index) => (
                                        <span key={index} style={skillStyle}>{skill}</span>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-md-8">
                        <div style={cardStyle}>
                            <h4><BsPersonCircle style={iconStyle} />Personal Details</h4>
                            <p><FiMail style={iconStyle} /><strong>Email:</strong> {profileData.email || 'No Email Provided'}</p>
                            <p><BsBriefcaseFill style={iconStyle} /><strong>Gender:</strong> {profileData.gender || 'Not Specified'}</p>
                            <p><AiOutlineGlobal style={iconStyle} /><strong>Country:</strong> {profileData.country || 'Unknown'}</p>
                            <p><FiClock style={iconStyle} /><strong>Timezone:</strong> {profileData.timezone || 'Not Set'}</p>
                            <p><BsFillTagFill style={iconStyle} /><strong>Hourly Rate:</strong> {profileData.hourlyRate || 'Not Provided'}</p>
                            <p><BsBuilding style={iconStyle} /><strong>Industry Category:</strong> {profileData.industryCategory || 'Not Provided'}</p>
                        </div>
                        <div style={cardStyle}>
                            <h4><BsBriefcaseFill style={iconStyle} />Work Experience</h4>
                            {profileData.workExperience.length ? profileData.workExperience.map((item, index) => (
                                <div key={index} style={{ marginBottom: '10px' }}>
                                    <h5>{item.title}</h5>
                                    <p>{removeHtmlTags(item.description)}</p>
                                </div>
                            )) : <p>No work experience listed.</p>}
                        </div>
                        <div style={cardStyle}>
                            <h4><IoSchoolOutline style={iconStyle} />Certifications</h4>
                            {profileData.certifications.length ? profileData.certifications.map((cert, index) => (
                                <div key={index} style={{ marginBottom: '10px' }}>
                                    <h5>{cert.title} from {cert.issuer}</h5>
                                    <p>{cert.country}, {new Date(cert.date.seconds * 1000).toLocaleDateString()}</p>
                                </div>
                            )) : <p>No certifications listed.</p>}
                        </div>
                        <div style={cardStyle}>
                            <h4><FiDownload style={iconStyle} />Resume</h4>
                            {profileData.cvUrl ? (
                                <div>
                                    <iframe src={profileData.cvUrl} style={{ width: '100%', height: '500px' }} title="Resume Preview" frameBorder="0" allowFullScreen></iframe>
                                    <a href={profileData.cvUrl} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#0d6efd', marginTop: '10px' }}>
                                        <FiDownload size="24" /><span style={{ marginLeft: '10px' }}>Download Resume</span>
                                    </a>
                                </div>
                            ) : <p>No resume available.</p>}
                        </div>
                        <div style={cardStyle}>
                            <h4><FiDownload style={iconStyle} />Video Introduction</h4>
                            {profileData.videoIntroUrl ? (
                                <iframe src={profileData.videoIntroUrl} style={{ width: '100%', height: '300px' }} title="Video Introduction" frameBorder="0" allowFullScreen></iframe>
                            ) : <p>No video introduction available.</p>}
                        </div>
                        <div style={cardStyle}>
                            <h4><AiOutlineGlobal style={iconStyle} />LinkedIn Profile</h4>
                            {profileData.linkedin ? (
                                <a href={profileData.linkedin} target="_blank" rel="noopener noreferrer" style={{ color: '#0d6efd' }}>View LinkedIn Profile</a>
                            ) : <p>No LinkedIn profile available.</p>}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <ScrollTop />
        </>
    );
}
