import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, getDoc, query, where, getDocs, collection, addDoc } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { db, auth, storage } from '../firebase';
import styled from 'styled-components';
import ExpertNavbar from './expertnavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImage, faUser, faEnvelope, faGlobe, faList, faClock, faVenusMars, faBriefcase, faIndustry,
  faLink, faTasks, faCalendar, faUpload, faPaperPlane, faPlus, faTimes, faArrowLeft, faVideo
} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { onAuthStateChanged } from 'firebase/auth';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = styled.div`
  max-width: 900px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background: white;
  overflow: hidden;

  @media (max-width: 768px) {
    margin: 10px;
  }
`;

const Section = styled.div`
  padding: 20px;
  background: linear-gradient(to right, #ffffff, #f8f9fa);
  border-bottom: 2px solid #e9ecef;
  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Label = styled.label`
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
  color: #343a40;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 12px;
  border: 2px solid #dee2e6;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 12px;
  border: 2px solid #dee2e6;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const Button = styled.button`
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s, transform 0.2s;
  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
`;

const SkillButton = styled(Button)`
  background-color: #f8f9fa;
  color: #343a40;
  margin-right: 5px;
  margin-bottom: 5px;
`;

const CloseButton = styled.button`
  background-color: #dc3545;
  border: none;
  color: white;
  padding: 0 6px;
  line-height: 1.5;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 5px;
  font-size: 12px;
  transition: background-color 0.2s;
  &:hover {
    background-color: #c82333;
  }
`;

const VideoContainer = styled.div`
  margin-bottom: 10px;
`;

const Video = styled.video`
  width: 100%;
  border-radius: 8px;
  margin-top: 10px;
`;

function EditProfile() {
  const navigate = useNavigate();
  const [profileInfo, setProfileInfo] = useState({
    profileImage: '',
    name: '',
    email: '',
    country: '',
    timezone: '',
    gender: '',
    currentJobTitle: '',
    industryCategory: '',
    workExperience: [{
      title: '',
      spanStart: new Date(),
      spanEnd: new Date(),
      description: ''
    }],
    primarySkills: [],
    availableSkills: [],
    socialLinks: [{ url: '' }],
    certifications: [{ title: '', issuer: '', date: new Date() }],
    linkedin: '',
    cvUrl: '',
    videoIntroUrl: ''
  });

  const [skillsInput, setSkillsInput] = useState('');
  const [cvPreview, setCvPreview] = useState('');
  const [videoPreview, setVideoPreview] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);
  const videoRef = useRef(null);
  const recordedVideoRef = useRef(null);

  useEffect(() => {
    setProfileInfo(prevState => ({
      ...prevState,
      workExperience: Array.isArray(prevState.workExperience) ? prevState.workExperience : [],
      primarySkills: Array.isArray(prevState.primarySkills) ? prevState.primarySkills : [],
      availableSkills: Array.isArray(prevState.availableSkills) ? prevState.availableSkills : [],
      socialLinks: Array.isArray(prevState.socialLinks) ? prevState.socialLinks : [],
      certifications: Array.isArray(prevState.certifications) ? prevState.certifications : [],
    }));
  }, []);

  const categories = [
    'Marketing', 'Fullstack Engineer', 'Data Science', 'Product Management', 'Graphic Design',
    'UI/UX Design', 'Software Development', 'Project Management', 'Sales', 'Customer Support',
    'Human Resources', 'Finance', 'Legal', 'Education', 'Healthcare',
    'Real Estate', 'Logistics', 'Manufacturing', 'Hospitality', 'Other'
  ];

  const skillsByCategory = {
    "Marketing": [
      "SEO", "Digital Advertising", "Content Strategy", "Social Media", "Email Marketing",
      "Brand Management", "Analytics", "PPC", "Content Creation", "Video Marketing",
      "Market Research", "Consumer Behavior", "Strategic Planning", "CRM", "Public Relations",
      "Event Planning", "Copywriting", "Influencer Marketing", "Affiliate Marketing", "Graphic Design"
    ],
    "Fullstack Engineer": [
      "JavaScript", "React", "Node.js", "SQL", "NoSQL", "CSS", "HTML", "Angular", "Vue.js", "Python",
      "PHP", "Ruby on Rails", "Java", "C#", ".NET", "TypeScript", "API Development", "Microservices", "AWS", "Docker"
    ],
    "Data Science": [
      "Python", "R", "SQL", "Machine Learning", "Deep Learning", "Data Visualization", "Big Data", "Statistics",
      "Data Mining", "Data Wrangling", "AI", "Neural Networks", "Predictive Modeling", "NLP", "Computer Vision",
      "Bayesian Methods", "Regression Analysis", "Decision Trees", "Cluster Analysis", "Data Integration"
    ],
    "Product Management": [
      "Product Lifecycle Management", "Market Analysis", "Scrum", "Agile", "User Testing",
      "Roadmap Planning", "Feature Definition", "Product Strategy", "Competitive Analysis", "Pricing Strategy",
      "Customer Engagement", "KPIs", "Stakeholder Management", "Backlog Management", "Sprint Planning",
      "Market Positioning", "Launch Management", "Cross-functional Team Leadership", "UX/UI Design", "Analytics"
    ],
    "Graphic Design": [
      "Adobe Photoshop", "Illustrator", "InDesign", "Sketch", "Figma", "Corel Draw", "Adobe XD", "UX Design",
      "UI Design", "Print Design", "Digital Illustration", "Branding", "Typography", "Color Theory", "Layout Design",
      "Motion Graphics", "3D Design", "Packaging Design", "Logo Creation", "Web Design"
    ],
    "UI/UX Design": [
      "User Research", "Wireframing", "Prototyping", "User Interface Design", "User Experience Design",
      "Usability Testing", "Interaction Design", "Visual Design", "Information Architecture", "Responsive Design",
      "Figma", "Sketch", "Adobe XD", "InVision", "Axure", "Accessibility", "CSS", "HTML", "JavaScript", "Agile UX"
    ],
    "Software Development": [
      "Java", "Python", "C#", "Ruby", "JavaScript", "PHP", "C++", "Mobile Development", "Web Development",
      "Software Testing", "DevOps", "Agile Methodologies", "API Design", "Security Practices", "Source Control",
      "Database Management", "Cloud Computing", "Performance Optimization", "System Architecture", "Continuous Integration"
    ],
    "Project Management": [
      "Agile Project Management", "Waterfall", "Scrum", "Kanban", "Risk Management", "Resource Allocation",
      "Stakeholder Communication", "Budget Management", "Scheduling", "Quality Control",
      "Procurement Management", "Change Management", "Project Planning", "Team Leadership", "Performance Tracking",
      "MS Project", "JIRA", "Asana", "Trello", "Lean Management"
    ],
    "Sales": [
      "Customer Relationship Management", "Negotiation", "Lead Generation", "Sales Planning", "Market Analysis",
      "Product Knowledge", "Customer Needs Analysis", "Sales Presentations", "Closing Techniques", "Team Management",
      "Revenue Growth", "Account Management", "B2B Sales", "B2C Sales", "Contract Negotiation",
      "Direct Sales", "Channel Sales", "E-commerce Sales", "Social Selling", "Sales Reporting"
    ],
    "Customer Support": [
      "Customer Service", "Technical Support", "Help Desk", "Client Relations", "Troubleshooting",
      "Product Support", "User Training", "Complaint Resolution", "Customer Satisfaction Analysis", "Service Level Agreements",
      "Support Ticket Management", "Live Chat Support", "Call Center Operations", "Feedback Collection", "Escalation Procedures",
      "Knowledge Base Maintenance", "Customer Onboarding", "CRM Software", "Remote Support Tools", "Customer Engagement"
    ],
    "Human Resources": [
      "Recruiting", "Talent Management", "Employee Relations", "Performance Management", "HR Policies",
      "Benefits Administration", "Workforce Planning", "Compliance", "Training & Development", "Succession Planning",
      "Compensation and Payroll", "Labor Relations", "HR Metrics", "Employee Engagement", "HR Software",
      "Organizational Development", "Diversity and Inclusion", "Employee Wellness", "Conflict Resolution", "Strategic HRM"
    ],
    "Finance": [
      "Financial Analysis", "Budgeting", "Forecasting", "Accounting", "Financial Reporting",
      "Risk Management", "Audit Compliance", "Tax Planning", "Investment Strategies", "Cash Flow Management",
      "Corporate Finance", "Cost Reduction", "Financial Planning", "Treasury Management", "Credit Analysis",
      "Bookkeeping", "GAAP Compliance", "Regulatory Compliance", "Financial Modeling", "ERP Systems"
    ],
    "Legal": [
      "Contract Law", "Corporate Law", "Litigation", "Intellectual Property", "Compliance",
      "Legal Research", "Legal Writing", "Mergers & Acquisitions", "Employment Law", "Real Estate Law",
      "Tax Law", "Bankruptcy", "Data Privacy", "Environmental Law", "Family Law",
      "Criminal Law", "International Law", "Civil Rights Law", "Estate Planning", "Arbitration"
    ],
    "Education": [
      "Curriculum Development", "Educational Leadership", "Classroom Management", "Educational Technology", "Student Assessment",
      "Special Education", "E-Learning", "Instructional Design", "Early Childhood Education", "Higher Education",
      "Teaching", "Academic Advising", "Learning Management Systems", "Student Engagement", "Course Development",
      "Education Policy", "Teacher Training", "Distance Learning", "Education Research", "Education Administration"
    ],
    "Healthcare": [
      "Patient Care", "Medical Knowledge", "Healthcare Administration", "Clinical Skills", "Emergency Care",
      "Medical Diagnostics", "Healthcare Compliance", "Surgical Procedures", "Patient Safety", "Pharmacology",
      "Therapeutic Management", "Medical Research", "Health Information Systems", "Public Health", "Mental Health",
      "Nursing", "Medical Imaging", "Healthcare Consulting", "Occupational Therapy", "Physical Therapy"
    ],
    "Real Estate": [
      "Property Management", "Real Estate Development", "Leasing", "Real Estate Economics", "Property Marketing",
      "Negotiation", "Market Analysis", "Real Estate Transactions", "Urban Planning", "Portfolio Management",
      "Commercial Real Estate", "Residential Homes", "Real Estate Financing", "Land Acquisition", "Real Estate Appraisal",
      "Investment Properties", "Due Diligence", "Building Management", "Property Law", "Escrow Management"
    ],
    "Logistics": [
      "Supply Chain Management", "Warehousing", "Transportation", "Logistics Planning", "Inventory Management",
      "Freight Forwarding", "Demand Planning", "Supply Chain Optimization", "Logistics Analysis", "International Logistics",
      "Customs Regulations", "Distribution Management", "Fleet Management", "Supply Chain Security", "Materials Management",
      "Procurement", "Shipping", "Supply Chain Software", "Vendor Management", "Operational Efficiency"
    ],
    "Manufacturing": [
      "Production Planning", "Manufacturing Processes", "Quality Control", "Lean Manufacturing", "Six Sigma",
      "Supply Chain Management", "Equipment Maintenance", "Product Development", "Continuous Improvement", "Safety Management",
      "Operations Management", "Cost Reduction", "Inventory Control", "Factory Automation", "Process Engineering",
      "ISO Standards", "Materials Science", "Workforce Management", "Plant Management", "Compliance Management"
    ],
    "Hospitality": [
      "Customer Service", "Hotel Management", "Event Management", "Food and Beverage Management", "Hospitality Marketing",
      "Housekeeping", "Front Office", "Revenue Management", "Reservations", "Guest Relations",
      "Hospitality Technology", "Tourism", "Catering Management", "Property Management Systems", "Banquet Operations",
      "Hospitality Training", "Menu Planning", "Hospitality Law", "Spa Management", "Crisis Management"
    ],
    "Other": [
      "Other"
    ]
  };

  const timezoneMapping = {
    "USA": ["America/New_York", "America/Chicago", "America/Denver", "America/Los_Angeles"],
    "Canada": ["America/Toronto", "America/Vancouver", "America/Edmonton", "America/Halifax"],
    "United Kingdom": ["Europe/London"],
    "Australia": ["Australia/Sydney", "Australia/Melbourne", "Australia/Perth", "Australia/Brisbane"],
    "Germany": ["Europe/Berlin"],
    "France": ["Europe/Paris"],
    "Spain": ["Europe/Madrid"],
    "Italy": ["Europe/Rome"],
    "Brazil": ["America/Sao_Paulo", "America/Rio_Branco", "America/Fortaleza"],
    "China": ["Asia/Shanghai"],
    "India": ["Asia/Kolkata"],
    "Japan": ["Asia/Tokyo"],
    "Russia": ["Europe/Moscow", "Asia/Vladivostok", "Asia/Novosibirsk"],
    "South Africa": ["Africa/Johannesburg"],
    "Mexico": ["America/Mexico_City"],
    "Indonesia": ["Asia/Jakarta", "Asia/Makassar", "Asia/Jayapura"],
    "Netherlands": ["Europe/Amsterdam"],
    "Turkey": ["Europe/Istanbul"],
    "Saudi Arabia": ["Asia/Riyadh"],
    "Switzerland": ["Europe/Zurich"],
    "Argentina": ["America/Buenos_Aires"],
    "Sweden": ["Europe/Stockholm"],
    "Nigeria": ["Africa/Lagos"],
    "Poland": ["Europe/Warsaw"],
    "Belgium": ["Europe/Brussels"],
    "Thailand": ["Asia/Bangkok"],
    "Austria": ["Europe/Vienna"],
    "Norway": ["Europe/Oslo"],
    "Iran": ["Asia/Tehran"],
    "United Arab Emirates": ["Asia/Dubai"],
    "Colombia": ["America/Bogota"],
    "South Korea": ["Asia/Seoul"],
    "Malaysia": ["Asia/Kuala_Lumpur"],
    "Singapore": ["Asia/Singapore"],
    "Denmark": ["Europe/Copenhagen"],
    "Finland": ["Europe/Helsinki"],
    "Chile": ["America/Santiago"],
    "Ireland": ["Europe/Dublin"],
    "Pakistan": ["Asia/Karachi"],
    "Egypt": ["Africa/Cairo"],
    "Philippines": ["Asia/Manila"],
    "Portugal": ["Europe/Lisbon"],
    "Czech Republic": ["Europe/Prague"],
    "Romania": ["Europe/Bucharest"],
    "Vietnam": ["Asia/Ho_Chi_Minh"],
    "Israel": ["Asia/Jerusalem"],
    "Greece": ["Europe/Athens"],
    "Hong Kong": ["Asia/Hong_Kong"],
    "Peru": ["America/Lima"]
  };

  const countries = Object.keys(timezoneMapping);

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    const categorySkills = skillsByCategory[selectedCategory] || [];
    setProfileInfo(prevState => ({
      ...prevState,
      industryCategory: selectedCategory,
      availableSkills: categorySkills,
      primarySkills: []
    }));
  };

  const handleChangeCountry = (e) => {
    const selectedCountry = e.target.value;
    const selectedTimezones = timezoneMapping[selectedCountry] || [];
    setProfileInfo(prevState => ({
      ...prevState,
      country: selectedCountry,
      timezone: selectedTimezones.length === 1 ? selectedTimezones[0] : ''
    }));
  };

  const handleChangeTimezone = (e) => {
    const selectedTimezone = e.target.value;
    setProfileInfo(prevState => ({
      ...prevState,
      timezone: selectedTimezone
    }));
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchProfile();
      } else {
        console.log('User is not logged in');
        navigate('/expertlogin');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchProfile = async () => {
    if (!auth.currentUser || !auth.currentUser.email) {
      console.log('No user logged in');
      navigate('/expertlogin');
      return;
    }

    const userEmail = auth.currentUser.email;

    try {
      let docRef = doc(db, "profiles", userEmail);
      let docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        const queryRef = query(collection(db, "profiles"), where("email", "==", userEmail));
        const querySnap = await getDocs(queryRef);

        if (!querySnap.empty) {
          docSnap = querySnap.docs[0];
        }
      }

      if (docSnap.exists()) {
        let data = docSnap.data();
        data.primarySkills = Array.isArray(data.primarySkills) ? data.primarySkills : [];
        data.workExperience = Array.isArray(data.workExperience) ? data.workExperience.map(exp => ({
          ...exp,
          spanStart: exp.spanStart ? new Date(exp.spanStart.seconds * 1000) : new Date(),
          spanEnd: exp.spanEnd ? new Date(exp.spanEnd.seconds * 1000) : new Date(),
        })) : [];
        data.availableSkills = Array.isArray(data.availableSkills) ? data.availableSkills : [];
        data.socialLinks = Array.isArray(data.socialLinks) ? data.socialLinks : [];
        data.certifications = Array.isArray(data.certifications) ? data.certifications.map(cert => ({
          ...cert,
          date: cert.date ? new Date(cert.date.seconds * 1000) : new Date(),
        })) : [];
        setProfileInfo(data);
        setCvPreview(data.cvUrl);
        setVideoPreview(data.videoIntroUrl);
      } else {
        console.log("No profile document found for the email:", userEmail);
      }
    } catch (error) {
      console.error("Error fetching profile: ", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileInfo(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSkillsChange = (e) => setSkillsInput(e.target.value);

  const addSkill = (skillType) => {
    if (!skillsInput.trim()) return;
    setProfileInfo(prevState => ({
      ...prevState,
      [skillType]: [...prevState[skillType], skillsInput]
    }));
    setSkillsInput('');
  };

  const handleSocialLinkChange = (index, newUrl) => {
    const updatedLinks = profileInfo.socialLinks.map((link, i) => {
      if (i === index) {
        return { ...link, url: newUrl };
      }
      return link;
    });
    setProfileInfo(prevState => ({ ...prevState, socialLinks: updatedLinks }));
  };

  const addSocialLink = () => {
    const newLink = { url: '' };
    setProfileInfo(prevState => ({
      ...prevState,
      socialLinks: [...prevState.socialLinks, newLink]
    }));
  };

  const removeSocialLink = (index) => {
    const filteredLinks = profileInfo.socialLinks.filter((_, i) => i !== index);
    setProfileInfo(prevState => ({ ...prevState, socialLinks: filteredLinks }));
  };

  const handleWorkExperienceChange = (index, field, value) => {
    let newWorkExperience = Array.isArray(profileInfo.workExperience) ? [...profileInfo.workExperience] : [];
    if (field === 'spanStart' || field === 'spanEnd') {
      const dateValue = new Date(value);
      if (!isNaN(dateValue.getTime())) {
        newWorkExperience[index][field] = dateValue;
      } else {
        console.error('Invalid date value');
        return;
      }
    } else {
      newWorkExperience[index][field] = value;
    }
    setProfileInfo(prevState => ({ ...prevState, workExperience: newWorkExperience }));
  };

  const addWorkExperience = () => {
    let newWorkExperience = Array.isArray(profileInfo.workExperience) ? [...profileInfo.workExperience] : [];
    newWorkExperience.push({ title: '', spanStart: new Date(), spanEnd: new Date(), description: '' });
    setProfileInfo(prevState => ({ ...prevState, workExperience: newWorkExperience }));
  };

  const removeWorkExperience = (index) => {
    let newWorkExperience = Array.isArray(profileInfo.workExperience) ? [...profileInfo.workExperience] : [];
    newWorkExperience = newWorkExperience.filter((_, i) => i !== index);
    setProfileInfo(prevState => ({ ...prevState, workExperience: newWorkExperience }));
  };

  const handleProfileImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileRef = storageRef(storage, `profileImages/${auth.currentUser.uid}/${file.name}`);
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);
      setProfileInfo(prevState => ({ ...prevState, profileImage: fileUrl }));
    }
  };

  const handleCvUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileRef = storageRef(storage, `cvs/${auth.currentUser.uid}/${file.name}`);
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);
      setCvPreview(fileUrl);
      setProfileInfo(prevState => ({ ...prevState, cvUrl: fileUrl }));
    }
  };

  const handleStartRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }
    const recorder = new MediaRecorder(stream);
    setMediaRecorder(recorder);

    const chunks = [];
    recorder.ondataavailable = (event) => chunks.push(event.data);
    recorder.onstop = () => {
      const blob = new Blob(chunks, { type: 'video/webm' });
      setVideoBlob(blob);
      setVideoPreview(URL.createObjectURL(blob));
      if (recordedVideoRef.current) {
        recordedVideoRef.current.srcObject = null;
        recordedVideoRef.current.src = URL.createObjectURL(blob);
      }
    };

    recorder.start();
    setIsRecording(true);
  };

  const handleStopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      setIsRecording(false);
    }
  };

  const handleVideoUpload = async () => {
    if (videoBlob) {
      const fileRef = storageRef(storage, `videos/${Date.now()}.webm`);
      const uploadTask = uploadBytes(fileRef, videoBlob);

      uploadTask.on('state_changed',
        snapshot => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        error => {
          console.error('Upload failed:', error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setProfileInfo(prevState => ({ ...prevState, videoIntroUrl: downloadURL }));
        }
      );
    }
  };

  const handleCertificationChange = (index, field, value) => {
    let updatedCertifications = [...profileInfo.certifications];
    if (field === 'date') {
      updatedCertifications[index][field] = value;
    } else {
      updatedCertifications[index][field] = value;
    }
    setProfileInfo(prevState => ({ ...prevState, certifications: updatedCertifications }));
  };

  const addCertification = () => {
    setProfileInfo(prevState => ({
      ...prevState,
      certifications: [...prevState.certifications, { title: '', issuer: '', date: new Date() }]
    }));
  };

  const removeCertification = (index) => {
    setProfileInfo(prevState => ({
      ...prevState,
      certifications: prevState.certifications.filter((_, i) => i !== index)
    }));
  };

  const handleSkillsSelect = (e) => {
    const skill = e.target.value;
    if (skill && profileInfo.availableSkills && !profileInfo.primarySkills.includes(skill)) {
      setProfileInfo(prevState => ({
        ...prevState,
        primarySkills: [...prevState.primarySkills, skill]
      }));
    }
  };

  const removeSkill = (skillToRemove) => {
    setProfileInfo(prevState => ({
      ...prevState,
      primarySkills: prevState.primarySkills.filter(skill => skill !== skillToRemove)
    }));
  };

  const skillButtons = Array.isArray(profileInfo.primarySkills) ? profileInfo.primarySkills.map(skill => (
    <SkillButton key={skill}>
      {skill}
      <CloseButton onClick={() => removeSkill(skill)}>×</CloseButton>
    </SkillButton>
  )) : null;

  const handleSubmit = async (e) => {
    e.preventDefault();

    let videoUrl = profileInfo.videoIntroUrl;
    if (videoPreview) {
      const videoBlob = await fetch(videoPreview).then(r => r.blob());
      const fileRef = storageRef(storage, `videos/${auth.currentUser.uid}/${Date.now()}_video.webm`);
      await uploadBytes(fileRef, videoBlob);
      videoUrl = await getDownloadURL(fileRef);
    }

    try {
      await setDoc(doc(db, 'profiles', auth.currentUser.uid), { ...profileInfo, videoIntroUrl: videoUrl });
      await addDoc(collection(db, "expertsNotification"), {
        userId: auth.currentUser.uid,
        email: profileInfo.email,
        message: "Your Profile has been updated",
        read: false,
        timestamp: new Date(),
      });
      Swal.fire({
        icon: 'success',
        title: 'Profile updated successfully!',
        showConfirmButton: false,
        timer: 1500,
        background: 'white',
        customClass: {
          popup: 'smaller-popup',
        }
      });
      navigate('/expertdashboard');
    } catch (error) {
      console.error("Error updating profile: ", error);
      Swal.fire({
        icon: 'error',
        title: 'Failed to update profile.',
        text: error.message,
        background: 'white',
        customClass: {
          popup: 'smaller-popup',
        }
      });
    }
  };

  return (
    <>
      <ExpertNavbar />
      <Container>
        <Button type="button" style={{ backgroundColor: '#6c757d', color: 'white', margin: '10px auto' }} onClick={() => navigate('/expertdashboard')}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back to Dashboard
        </Button>
        <form onSubmit={handleSubmit}>
          <Section>
            <h2 style={{ textAlign: 'center' }}>Update Your Expert Profile</h2>
            <p style={{ textAlign: 'center' }}>Enhance your profile to better match opportunities that align with your skills and experience.</p>
          </Section>

          <Section>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <div style={{ flexBasis: '30%' }}>
                <Label><FontAwesomeIcon icon={faImage} /> Profile Image:*</Label>
                <Input type="file" accept="image/*" onChange={handleProfileImageUpload} required />
                {profileInfo.profileImage && <img src={profileInfo.profileImage} alt="Profile Preview" style={{ maxWidth: '200px', marginTop: '10px' }} />}
              </div>
              <div style={{ flexBasis: '30%' }}>
                <Label><FontAwesomeIcon icon={faUser} /> Name:*</Label>
                <Input type="text" name="name" value={profileInfo.name} onChange={handleChange} required />
              </div>
              <div style={{ flexBasis: '30%' }}>
                <Label><FontAwesomeIcon icon={faEnvelope} /> Email:*</Label>
                <Input type="email" name="email" value={profileInfo.email} onChange={handleChange} required />
              </div>
            </div>
          </Section>

          <Section>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <div style={{ flexBasis: '30%' }}>
                <Label><FontAwesomeIcon icon={faGlobe} /> Country:*</Label>
                <Select name="country" value={profileInfo.country} onChange={handleChangeCountry} required>
                  {countries.map(country => (
                    <option key={country} value={country}>{country}</option>
                  ))}
                </Select>
              </div>
              <div style={{ flexBasis: '30%' }}>
                <Label><FontAwesomeIcon icon={faClock} /> Timezone:*</Label>
                {timezoneMapping[profileInfo.country] && (
                  <Select name="timezone" value={profileInfo.timezone} onChange={handleChangeTimezone} required>
                    <option value="">Select Timezone</option>
                    {timezoneMapping[profileInfo.country].map(timezone => (
                      <option key={timezone} value={timezone}>{timezone}</option>
                    ))}
                  </Select>
                )}
              </div>
              <div style={{ flexBasis: '30%' }}>
                <Label><FontAwesomeIcon icon={faVenusMars} /> Gender (optional):</Label>
                <Select name="gender" value={profileInfo.gender} onChange={handleChange}>
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Select>
              </div>
            </div>
          </Section>

          <Section>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <div style={{ flexBasis: '30%' }}>
                <Label><FontAwesomeIcon icon={faBriefcase} /> Current Position:*</Label>
                <Input type="text" name="currentJobTitle" value={profileInfo.currentJobTitle} onChange={handleChange} required />
              </div>
              <div style={{ flexBasis: '30%' }}>
                <Label><FontAwesomeIcon icon={faIndustry} /> Industry Category:*</Label>
                <Select name="industryCategory" value={profileInfo.industryCategory} onChange={handleCategoryChange} required>
                  {categories.map(category => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </Select>
              </div>
              <div style={{ flexBasis: '100%' }}>
                <Label><FontAwesomeIcon icon={faTasks} /> Skills:</Label>
                <Select value="" onChange={handleSkillsSelect}>
                  {profileInfo.availableSkills.map(skill => (
                    <option key={skill} value={skill}>{skill}</option>
                  ))}
                </Select>
                <div>
                  {skillButtons}
                </div>
              </div>
            </div>
          </Section>

          <Section>
            <Label><FontAwesomeIcon icon={faLink} /> LinkedIn URL:</Label>
            <Input type="url" name="linkedin" value={profileInfo.linkedin} onChange={handleChange} placeholder="LinkedIn Profile URL" />
            <Label><FontAwesomeIcon icon={faLink} /> Social Media Links:</Label>
            {profileInfo.socialLinks.map((link, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <Input type="url" value={link.url} onChange={(e) => handleSocialLinkChange(index, e.target.value)} placeholder="Social Media URL" />
                <CloseButton onClick={() => removeSocialLink(index)}>×</CloseButton>
              </div>
            ))}
            <Button type="button" onClick={addSocialLink}><FontAwesomeIcon icon={faPlus} /> Add Link</Button>
          </Section>

          <Section>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              {profileInfo.workExperience.map((experience, index) => (
                <div key={index} style={{ flexBasis: '100%' }}>
                  <Label><FontAwesomeIcon icon={faList} /> Position Title:*</Label>
                  <Input type="text" name="title" value={experience.title} onChange={(e) => handleWorkExperienceChange(index, 'title', e.target.value)} required />
                  <Label><FontAwesomeIcon icon={faCalendar} /> Duration (Start - End):*</Label>
                  <DatePicker
                    selected={experience.spanStart}
                    onChange={(date) => handleWorkExperienceChange(index, 'spanStart', date)}
                    required
                    dateFormat="MMMM d, yyyy"
                    selectsStart
                    startDate={experience.spanStart}
                    endDate={experience.spanEnd}
                    wrapperClassName="datePicker"
                  />
                  <DatePicker
                    selected={experience.spanEnd}
                    onChange={(date) => handleWorkExperienceChange(index, 'spanEnd', date)}
                    required
                    dateFormat="MMMM d, yyyy"
                    selectsEnd
                    startDate={experience.spanStart}
                    endDate={experience.spanEnd}
                    wrapperClassName="datePicker"
                  />
                  <Label><FontAwesomeIcon icon={faList} /> Key Responsibilities:*</Label>
                  <Input type="text" name="description" value={experience.description} onChange={(e) => handleWorkExperienceChange(index, 'description', e.target.value)} required />
                  <Button type="button" onClick={() => removeWorkExperience(index)} style={{ backgroundColor: '#dc3545' }}>Remove Position</Button>
                </div>
              ))}
              <Button type="button" onClick={addWorkExperience} style={{ width: '100%', marginTop: '10px' }}><FontAwesomeIcon icon={faPlus} /> Add Position</Button>
            </div>
          </Section>

          <Section>
            <Label><FontAwesomeIcon icon={faUpload} /> CV Upload:*</Label>
            <Input type="file" accept=".pdf,.doc,.docx" onChange={handleCvUpload} required />
            {cvPreview && (
              <a href={cvPreview} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: '#007bff', marginTop: '10px' }}>View CV</a>
            )}
          </Section>

          <Section>
            <Label><FontAwesomeIcon icon={faVideo} /> Video Introduction (Maximum length, 3 minutes):*</Label>
            <VideoContainer>
              <video ref={videoRef} autoPlay muted style={{ width: '100%', borderRadius: '8px' }} />
              {isRecording ? (
                <Button type="button" onClick={handleStopRecording} style={{ backgroundColor: '#dc3545', marginTop: '10px' }}>Stop Recording</Button>
              ) : (
                <Button type="button" onClick={handleStartRecording} style={{ marginTop: '10px' }}>Start Recording</Button>
              )}
              {videoPreview && (
                <>
                  <Video src={videoPreview} controls style={{ marginTop: '10px', maxWidth: '100%', borderRadius: '8px' }} />
                  <Button type="button" onClick={() => setVideoPreview('')} style={{ backgroundColor: '#dc3545', marginTop: '10px' }}>Delete Video</Button>
                </>
              )}
              <Button type="button" onClick={handleVideoUpload} style={{ marginTop: '10px', backgroundColor: '#007bff', color: 'white' }}>Upload Video</Button>
            </VideoContainer>
          </Section>

          <Section>
            <Label>Certifications/Key Projects:</Label>
            {profileInfo.certifications.map((cert, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <Input type="text" placeholder="Certification Title" value={cert.title} onChange={(e) => handleCertificationChange(index, 'title', e.target.value)} />
                <Input type="text" placeholder="Issuer" value={cert.issuer} onChange={(e) => handleCertificationChange(index, 'issuer', e.target.value)} />
                <DatePicker
                  selected={cert.date}
                  onChange={(date) => handleCertificationChange(index, 'date', date)}
                  dateFormat="MMMM d, yyyy"
                  wrapperClassName="datePicker"
                />
                <CloseButton onClick={() => removeCertification(index)}>×</CloseButton>
              </div>
            ))}
            <Button type="button" onClick={addCertification}><FontAwesomeIcon icon={faPlus} /> Add Certification</Button>
          </Section>

          <Section>
            <Button type="submit" style={{ backgroundColor: '#007bff', color: 'white' }}><FontAwesomeIcon icon={faPaperPlane} /> Update Profile</Button>
          </Section>
        </form>
        <ToastContainer />
      </Container>
    </>
  );
}

export default EditProfile;
