import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import axios from 'axios';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';

import bg1 from "../assets/images/hero/bg.jpg";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import { FiArrowRightCircle, FiCreditCard, FiXCircle, FiCheckCircle } from "react-icons/fi";
import { FaRegCreditCard, FaUser, FaCalendarAlt, FaLock } from "react-icons/fa";
import { auth } from '../firebase';

const stripePromise = loadStripe("pk_test_51PJcw62L5LTeMJgucbFgthFQmBUE1Hm7ZCKck5lwp6RJESyxOviyluvNMdboSnQFvRBa7mTFMkW90r171HlubENP00OuF9qi9Q");

const PaymentForm = ({ packageDetails, onClose }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const db = getFirestore();

    const [cardDetails, setCardDetails] = useState({
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        focused: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCardDetails(prev => ({ ...prev, [name]: value }));
    };

    const handleInputFocus = (e) => {
        setCardDetails(prev => ({ ...prev, focused: e.target.name }));
    };

    const createPaymentIntent = async () => {
        const secretKey = "sk_test_51PJcw62L5LTeMJgu3YhYq09FDi7V4KT7IxJowlrG901PL7UF5Q6fbBAWLJYR5vHwHv4ysheMqWgQzuCyhJ1H50Lm00kf1xIDWI";
        const amount = parseFloat(packageDetails.price.replace('$', '')) * 100; // Convert price to cents

        try {
            const params = new URLSearchParams();
            params.append('amount', amount.toString());
            params.append('currency', 'usd');
            params.append('payment_method_types[]', 'card'); // Correctly format the array parameter

            const response = await axios.post('https://api.stripe.com/v1/payment_intents', params.toString(), {
                headers: {
                    'Authorization': `Bearer ${secretKey}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            });

            return response.data.client_secret;
        } catch (error) {
            console.error("Error creating payment intent:", error.response ? error.response.data : error.message);
            throw error;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setLoading(true);
        setMessage('');
        const cardElement = elements.getElement(CardElement);

        try {
            const clientSecret = await createPaymentIntent();

            // Confirm the payment with the card details
            const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                },
            });

            if (error) {
                console.error(error);
                setMessage(error.message);
                setLoading(false);
            } else {
                console.log("Payment successful:", paymentIntent);
                setMessage("Payment successful!");
                
                // Update the user plan in Firestore
                const user = auth.currentUser;
                const userRef = doc(db, 'users', user.uid);
                await updateDoc(userRef, { plan: 'Premium' });
                
                setLoading(false);
                // Redirect to client dashboard
                setTimeout(() => {
                    onClose();
                    navigate('/clientdashboard');
                }, 3000);
            }
        } catch (error) {
            console.error(error);
            setMessage("An unexpected error occurred.");
            setLoading(false);
        }
    };

    return (
        <div style={{
            position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, animation: 'fadeIn 0.3s ease-in-out'
        }}>
            <div style={{
                backgroundColor: 'white', padding: '30px', borderRadius: '10px', boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)', maxWidth: '800px', width: '90%', animation: 'slideIn 0.3s ease-in-out', display: 'flex', flexDirection: 'row', textAlign: 'center', position: 'relative'
            }}>
                <FiXCircle onClick={onClose} style={{ position: 'absolute', top: '15px', right: '15px', cursor: 'pointer', color: '#007bff', fontSize: '24px' }} />
                <div style={{ flex: 1, marginRight: '20px' }}>
                    <h2>{packageDetails.title} Plan</h2>
                    <p>{packageDetails.description}</p>
                    <ul style={{ listStyleType: 'none', padding: '0', marginBottom: '20px', textAlign: 'left' }}>
                        {packageDetails.features.map((feature, idx) => (
                            <li key={idx} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', color: '#343a40' }}>
                                <FiArrowRightCircle style={{ marginRight: '10px', color: '#007bff' }} />
                                {feature}
                            </li>
                        ))}
                    </ul>
                </div>
                <div style={{ flex: 1 }}>
                    <div style={{ marginBottom: '20px', animation: 'fadeIn 0.5s ease-in-out' }}>
                        <Cards
                            number={cardDetails.number}
                            name={cardDetails.name}
                            expiry={cardDetails.expiry}
                            cvc={cardDetails.cvc}
                            focused={cardDetails.focused}
                        />
                    </div>
                    <form onSubmit={handleSubmit} style={{ marginTop: '20px', animation: 'fadeIn 0.5s ease-in-out' }}>
                        <div style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px', position: 'relative' }}>
                            <FaRegCreditCard style={{ position: 'absolute', top: '15px', left: '10px', color: '#6c757d' }} />
                            <input
                                type="tel"
                                name="number"
                                placeholder="Card Number"
                                value={cardDetails.number}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                style={{ width: '100%', padding: '10px 10px 10px 30px', marginBottom: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ddd' }}
                            />
                        </div>
                        <div style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px', position: 'relative' }}>
                            <FaUser style={{ position: 'absolute', top: '15px', left: '10px', color: '#6c757d' }} />
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={cardDetails.name}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                style={{ width: '100%', padding: '10px 10px 10px 30px', marginBottom: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ddd' }}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px', width: '48%', position: 'relative' }}>
                                <FaCalendarAlt style={{ position: 'absolute', top: '15px', left: '10px', color: '#6c757d' }} />
                                <input
                                    type="tel"
                                    name="expiry"
                                    placeholder="MM/YY"
                                    value={cardDetails.expiry}
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    style={{ width: '100%', padding: '10px 10px 10px 30px', marginBottom: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ddd' }}
                                />
                            </div>
                            <div style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px', width: '48%', position: 'relative' }}>
                                <FaLock style={{ position: 'absolute', top: '15px', left: '10px', color: '#6c757d' }} />
                                <input
                                    type="tel"
                                    name="cvc"
                                    placeholder="CVC"
                                    value={cardDetails.cvc}
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    style={{ width: '100%', padding: '10px 10px 10px 30px', marginBottom: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ddd' }}
                                />
                            </div>
                        </div>
                        <div style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                            <CardElement options={{ style: { base: { fontSize: '16px', color: '#424770', '::placeholder': { color: '#aab7c4' } }, invalid: { color: '#9e2146' } } }} />
                        </div>
                        {message && (
                            <div style={{
                                marginBottom: '20px',
                                padding: '10px',
                                border: '1px solid',
                                borderColor: message === "Payment successful!" ? '#28a745' : '#dc3545',
                                borderRadius: '5px',
                                backgroundColor: message === "Payment successful!" ? '#d4edda' : '#f8d7da',
                                color: message === "Payment successful!" ? '#155724' : '#721c24',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                {message === "Payment successful!" ? <FiCheckCircle style={{ marginRight: '10px' }} /> : <FiXCircle style={{ marginRight: '10px' }} />}
                                {message}
                            </div>
                        )}
                        <button type="submit" disabled={!stripe || loading} style={{
                            backgroundColor: '#007bff', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', width: '100%', transition: 'background-color 0.3s',
                            position: 'relative', overflow: 'hidden', fontSize: '16px'
                        }}>
                            {loading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="spinner" style={{
                                        width: '24px', height: '24px', border: '3px solid rgba(255, 255, 255, 0.3)', borderTop: '3px solid white', borderRadius: '50%', animation: 'spin 1s linear infinite'
                                    }}></div>
                                </div>
                            ) : (
                                `Pay ${packageDetails.price}`
                            )}
                        </button>
                    </form>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                        <FiCreditCard style={{ width: '50px', height: '50px', margin: '0 10px', color: '#007bff' }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default function Pricing() {
    const [selectedPackage, setSelectedPackage] = useState(null);

    const packages = [
        {
            title: "Basic",
            description: "Ideal for Individuals",
            price: "$0.00",
            features: [
                "Limited Job Posts",
                "Basic Search Filters",
                "Limited Access to Candidates",
                "Limited Messaging",
                "Basic Support",
            ],
        },
        {
            title: "Premium",
            description: "Ideal for Businesses",
            price: "$4.99",
            features: [
                "Unlimited Job Posts",
                "Advanced Search Filters",
                "Full Access to Candidates",
                "Unlimited Messaging",
                "Priority Support",
            ],
        },
        {
            title: "Enterprise",
            description: "Customized Solutions",
            price: "Contact Us",
            features: [
                "Custom Search Filters",
                "Dedicated Account Manager",
                "Enterprise-Level Security",
                "24/7 Premium Support",
            ],
        },
    ];

    return (
        <>
     
            <section style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top', padding: '170px 0', position: 'relative' }}>
                <div style={{ background: 'rgba(0, 0, 0, 0.5)', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}></div>
                <div style={{ position: 'relative', zIndex: 1 }}>
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                        <h5 style={{ color: 'white', fontWeight: '600', marginBottom: '0' }}>Pricing Plans</h5>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <nav aria-label="breadcrumb">
                            <ul style={{ display: 'flex', padding: '0', margin: '0', listStyleType: 'none' }}>
                                <li style={{ marginRight: '5px' }}><Link to="/" style={{ color: '#fff' }}>Experts Blooms</Link></li>
                                <li style={{ color: '#fff' }}> / Pricing</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <div style={{ position: 'relative', overflow: 'hidden', backgroundColor: 'white' }}>
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ display: 'block', width: '100%' }}>
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>

            <section style={{ padding: '60px 0' }}>
                <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>
                        {packages.map((pkg, index) => (
                            <div key={index} style={{ flex: '1 1 30%', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)', borderRadius: '10px', overflow: 'hidden', backgroundColor: 'white' }}>
                                <div style={{ backgroundColor: pkg.title === "Enterprise" ? '#007bff' : '#343a40', padding: '40px 20px', color: 'white' }}>
                                    <h5 style={{ margin: '0' }}>{pkg.title}</h5>
                                    <p style={{ margin: '0' }}>{pkg.description}</p>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f8f9fa', padding: '20px', borderBottom: '1px solid #dee2e6' }}>
                                    <span style={{ fontSize: '16px' }}>{pkg.price === "Contact Us" ? "" : "$"}</span>
                                    <span style={{ fontSize: '24px', marginLeft: '5px' }}>{pkg.price}</span>
                                    {pkg.price !== "Contact Us" && <span style={{ fontSize: '16px', alignSelf: 'flex-end', marginLeft: '5px' }}>/mo</span>}
                                </div>
                                <div style={{ padding: '20px', backgroundColor: '#f8f9fa' }}>
                                    <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
                                        {pkg.features.map((feature, idx) => (
                                            <li key={idx} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', color: '#343a40' }}>
                                                <FiArrowRightCircle style={{ marginRight: '10px' }} />
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <button 
                                            onClick={() => setSelectedPackage(pkg)} 
                                            style={{ backgroundColor: '#007bff', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer' }}
                                        >
                                            {pkg.price === "Contact Us" ? "Contact Sales" : "Get Started"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {selectedPackage && (
                        <Elements stripe={stripePromise}>
                            <PaymentForm packageDetails={selectedPackage} onClose={() => setSelectedPackage(null)} />
                        </Elements>
                    )}
                </div>
            </section>
            <Footer />
            <ScrollTop />
        </>
    );
}

// CSS animations in the same file
const style = document.createElement('style');
style.textContent = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
    }
    to {
        transform: translateY(0);
    }

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f8f9fa;
    color: #343a40;
}

h2, h5 {
    font-family: 'Roboto', sans-serif;
}

input {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
}

button {
    font-size: 16px;
}
`;
document.head.append(style);
