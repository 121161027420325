import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc, collection, getDocs, arrayUnion } from 'firebase/firestore';
import { db, auth } from "../firebase";
import { FaPencilAlt } from "react-icons/fa";
import { Modal, Button, Form } from 'react-bootstrap';
import styled from "styled-components";
import Footer from "../components/footer";
import ScrollTop from '../components/scrollTop';
import CustomNavbar from "./customnavbar";
import { Icon } from '@iconify/react';
import userIcon from '@iconify-icons/mdi/account-circle';
import briefcaseIcon from '@iconify-icons/mdi/briefcase';
import certificateIcon from '@iconify-icons/mdi/certificate';
import resumeIcon from '@iconify-icons/mdi/file-document';
import videoIcon from '@iconify-icons/mdi/video';
import linkedinIcon from '@iconify-icons/mdi/linkedin';
import projectIcon from '@iconify-icons/mdi/format-list-bulleted';

// Styled Components
const ProfileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: #f4f7fc;
`;

const LeftColumn = styled.div`
  flex: 1;
  max-width: 300px;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const RightColumn = styled.div`
  flex: 2;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const ProfileImage = styled.img`
  width: 100%;
  border-radius: 15px;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const SkillTag = styled.span`
  display: inline-block;
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border-radius: 20px;
  margin: 5px;
  font-size: 14px;
`;

const EditableSection = styled.div`
  position: relative;
  &:hover .edit-icon {
    display: block;
  }
`;

const EditIcon = styled(FaPencilAlt)`
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #007bff;
`;

const ProfileCompletionBar = styled.div`
  margin-bottom: 20px;
`;

const ProgressBar = styled.div`
  height: 20px;
  width: 100%;
  background-color: #e9ecef;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
`;

const ProgressFill = styled.div`
  height: 100%;
  background-color: #28a745;
  width: ${props => props.width || '0%'};
`;

const ProjectGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const ProjectCard = styled.div`
  width: calc(33.333% - 20px);
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s;
  &:hover {
    transform: translateY(-10px);
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const ProjectInfo = styled.div`
  padding: 15px;
`;

const ProjectTitle = styled.h5`
  font-size: 1rem;
  margin-bottom: 10px;
`;

const ProjectLink = styled.a`
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const AvailabilityBadge = styled.div`
  padding: 10px;
  color: #fff;
  background-color: ${props => (props.available ? '#28a745' : '#dc3545')};
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
`;

// Main Component
export default function CandidateProfileEdit() {
  const { email } = useParams();
  const [profileData, setProfileData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, firebaseUser => {
      if (!firebaseUser) {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchProfileData = async () => {
      const docRef = collection(db, "profiles");
      const snapshot = await getDocs(docRef);
      const data = snapshot.docs.map(doc => doc.data()).find(profile => profile.email === email);
      if (data) {
        setProfileData(data);
        calculateCompletionPercentage(data);
      }
    };
    fetchProfileData();
  }, [email]);

  const calculateCompletionPercentage = (data) => {
    const totalFields = 10; // Update this to match the number of fields you consider for completion
    let completedFields = 0;

    if (data.profileImage) completedFields++;
    if (data.gender) completedFields++;
    if (data.country) completedFields++;
    if (data.timezone) completedFields++;
    if (data.currentJobTitle) completedFields++;
    if (data.primarySkills.length > 0) completedFields++;
    if (data.certifications.length > 0) completedFields++;
    if (data.cvUrl) completedFields++;
    if (data.videoIntroUrl) completedFields++;
    if (data.linkedin) completedFields++;

    const percentage = Math.floor((completedFields / totalFields) * 100);
    setCompletionPercentage(percentage);
  };

  const handleEdit = (field, content) => {
    setModalContent({ field, content });
    setShowModal(true);
  };

  const handleSave = async (field, newValue) => {
    const profileRef = doc(db, "profiles", profileData.id);
    const updatedData = { ...profileData, [field]: newValue };
    await updateDoc(profileRef, updatedData);
    setProfileData(updatedData);
    calculateCompletionPercentage(updatedData);
    setShowModal(false);
  };

  const handleProjectAdd = async (newProject) => {
    const profileRef = doc(db, "profiles", profileData.id);
    const updatedData = {
      ...profileData,
      projects: arrayUnion(newProject),
    };
    await updateDoc(profileRef, updatedData);
    setProfileData(updatedData);
    calculateCompletionPercentage(updatedData);
    setShowModal(false);
  };

  const handleUploadChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    // Upload logic here (using Firebase storage)
    // Once uploaded, update the `profileData` and save it in Firestore
  };

  const toggleAvailability = async () => {
    const updatedAvailability = !profileData.available;
    await handleSave('available', updatedAvailability);
  };

  const renderModal = () => {
    if (!modalContent) return null;

    const { field, content } = modalContent;

    return (
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit {field}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Render specific form elements based on the field being edited */}
          {field === 'profileImage' || field === 'cvUrl' || field === 'videoIntroUrl' || field === 'projectThumbnail' ? (
            <Form.Group>
              <Form.File label="Upload File" onChange={handleUploadChange} />
              <Button onClick={handleFileUpload}>Upload</Button>
            </Form.Group>
          ) : (
            <input
              type="text"
              className="form-control"
              defaultValue={content}
              onChange={(e) => setModalContent({ ...modalContent, content: e.target.value })}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleSave(field, modalContent.content)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <CustomNavbar />
      <ProfileContainer>
        <LeftColumn>
          <EditableSection>
            <ProfileImage
              src={profileData.profileImage || 'https://via.placeholder.com/150'}
              alt="Profile"
            />
            <EditIcon
              className="edit-icon"
              onClick={() => handleEdit('profileImage', profileData.profileImage)}
            />
          </EditableSection>
          <h3 style={{ marginTop: '20px', textAlign: 'center' }}>{profileData.name}</h3>
          <p style={{ color: '#666', textAlign: 'center', marginBottom: '20px' }}>
            {profileData.currentJobTitle || 'No Job Title'}
          </p>
          <ProfileCompletionBar>
            <span>Profile Completion: {completionPercentage}%</span>
            <ProgressBar>
              <ProgressFill width={`${completionPercentage}%`} />
            </ProgressBar>
          </ProfileCompletionBar>
          {profileData.primarySkills && (
            <div style={{ marginBottom: '20px' }}>
              <h5>Skills</h5>
              <div>
                {profileData.primarySkills.map((skill, index) => (
                  <SkillTag key={index}>{skill}</SkillTag>
                ))}
              </div>
              <EditIcon
                className="edit-icon"
                onClick={() => handleEdit('primarySkills', profileData.primarySkills.join(', '))}
              />
            </div>
          )}
          <AvailabilityBadge
            available={profileData.available}
            onClick={toggleAvailability}
          >
            {profileData.available ? 'Available' : 'Not Available'}
          </AvailabilityBadge>
        </LeftColumn>

        <RightColumn>
          <EditableSection>
            <SectionTitle>
              Personal Details
              <Icon icon={userIcon} style={{ marginLeft: '10px', fontSize: '24px' }} />
            </SectionTitle>
            <EditIcon
              className="edit-icon"
              onClick={() => handleEdit('personalDetails', profileData)}
            />
            <p>Email: {profileData.email || 'No Email Provided'}</p>
            <p>Gender: {profileData.gender || 'Not Specified'}</p>
            <p>Country: {profileData.country || 'Unknown'}</p>
            <p>Timezone: {profileData.timezone || 'Not Set'}</p>
            <p>Hourly Rate: {profileData.hourlyRate || 'Not Provided'}</p>
            <p>Industry Category: {profileData.industryCategory || 'Not Provided'}</p>
          </EditableSection>

          <EditableSection>
            <SectionTitle>
              Work Experience
              <Icon icon={briefcaseIcon} style={{ marginLeft: '10px', fontSize: '24px' }} />
            </SectionTitle>
            <EditIcon
              className="edit-icon"
              onClick={() => handleEdit('workExperience', profileData.workExperience)}
            />
            {profileData.workExperience && profileData.workExperience.length ? (
              profileData.workExperience.map((item, index) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                  <h5>{item.title}</h5>
                  <p>{item.description}</p>
                </div>
              ))
            ) : (
              <p>No work experience listed.</p>
            )}
          </EditableSection>

          <EditableSection>
            <SectionTitle>
              Certifications
              <Icon icon={certificateIcon} style={{ marginLeft: '10px', fontSize: '24px' }} />
            </SectionTitle>
            <EditIcon
              className="edit-icon"
              onClick={() => handleEdit('certifications', profileData.certifications)}
            />
            {profileData.certifications && profileData.certifications.length ? (
              profileData.certifications.map((cert, index) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                  <h5>{cert.title} from {cert.issuer}</h5>
                  <p>{cert.country}, {new Date(cert.date.seconds * 1000).toLocaleDateString()}</p>
                </div>
              ))
            ) : (
              <p>No certifications listed.</p>
            )}
          </EditableSection>

          <EditableSection>
            <SectionTitle>
              Resume
              <Icon icon={resumeIcon} style={{ marginLeft: '10px', fontSize: '24px' }} />
            </SectionTitle>
            <EditIcon
              className="edit-icon"
              onClick={() => handleEdit('cvUrl', profileData.cvUrl)}
            />
            {profileData.cvUrl ? (
              <div>
                <iframe
                  src={profileData.cvUrl}
                  style={{ width: '100%', height: '300px' }}
                  title="Resume Preview"
                  frameBorder="0"
                  allowFullScreen
                />
                <a
                  href={profileData.cvUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                    color: '#0d6efd',
                    marginTop: '10px',
                  }}
                >
                  <span>Download Resume</span>
                </a>
              </div>
            ) : (
              <p>No resume available.</p>
            )}
          </EditableSection>

          <EditableSection>
            <SectionTitle>
              Video Introduction
              <Icon icon={videoIcon} style={{ marginLeft: '10px', fontSize: '24px' }} />
            </SectionTitle>
            <EditIcon
              className="edit-icon"
              onClick={() => handleEdit('videoIntroUrl', profileData.videoIntroUrl)}
            />
            {profileData.videoIntroUrl ? (
              <iframe
                src={profileData.videoIntroUrl}
                style={{ width: '100%', height: '300px' }}
                title="Video Introduction"
                frameBorder="0"
                allowFullScreen
              />
            ) : (
              <p>No video introduction available.</p>
            )}
          </EditableSection>

          <EditableSection>
            <SectionTitle>
              LinkedIn Profile
              <Icon icon={linkedinIcon} style={{ marginLeft: '10px', fontSize: '24px' }} />
            </SectionTitle>
            <EditIcon
              className="edit-icon"
              onClick={() => handleEdit('linkedin', profileData.linkedin)}
            />
            {profileData.linkedin ? (
              <a href={profileData.linkedin} target="_blank" rel="noopener noreferrer" style={{ color: '#0d6efd' }}>
                View LinkedIn Profile
              </a>
            ) : (
              <p>No LinkedIn profile available.</p>
            )}
          </EditableSection>

          <EditableSection>
            <SectionTitle>
              Projects
              <Icon icon={projectIcon} style={{ marginLeft: '10px', fontSize: '24px' }} />
            </SectionTitle>
            <EditIcon
              className="edit-icon"
              onClick={() => setShowModal(true)}
            />
            <ProjectGallery>
              {profileData.projects && profileData.projects.length > 0 ? (
                profileData.projects.map((project, index) => (
                  <ProjectCard key={index}>
                    <ProjectImage src={project.thumbnail || 'https://via.placeholder.com/150'} alt={project.name} />
                    <ProjectInfo>
                      <ProjectTitle>{project.name}</ProjectTitle>
                      <ProjectLink href={project.link} target="_blank">
                        View Project
                      </ProjectLink>
                    </ProjectInfo>
                  </ProjectCard>
                ))
              ) : (
                <p>No projects listed.</p>
              )}
            </ProjectGallery>
          </EditableSection>
        </RightColumn>
      </ProfileContainer>
      {renderModal()}
      <Footer />
      <ScrollTop />
    </>
  );
}
