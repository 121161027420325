import React, { useEffect, useState, useRef } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import ExpertNavbar from './expertnavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faBriefcase, faClock, faInfoCircle, faDownload, faCheck, faTimes, faSignature } from '@fortawesome/free-solid-svg-icons';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import SignatureCanvas from 'react-signature-canvas';
import { useNavigate } from 'react-router-dom';
import heroImage from '../assets/images/company/1.jpg';

const ExpertContract = () => {
  const [contracts, setContracts] = useState([]);
  const [stages, setStages] = useState([]);
  const auth = getAuth();
  const [userEmail, setUserEmail] = useState('');
  const [activeTab, setActiveTab] = useState('shortlisted');
  const [signature, setSignature] = useState('');
  const [signingContractId, setSigningContractId] = useState(null);
  const sigCanvasRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserEmail(user.email);
        await fetchContracts(user.email);
        await fetchStages(user.email);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  const fetchContracts = async (email) => {
    const contractsQuery = query(collection(db, 'contracts'), where('expertEmail', '==', email));
    const contractsSnapshot = await getDocs(contractsQuery);
    const contractsData = contractsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setContracts(contractsData);
  };

  const fetchStages = async (email) => {
    const stagesQuery = query(collection(db, 'jobApplications'), where('email', '==', email));
    const stagesSnapshot = await getDocs(stagesQuery);
    const stagesData = stagesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setStages(stagesData);
  };

  const handleAcceptContract = async (contractId) => {
    const contractRef = doc(db, 'contracts', contractId);
    await updateDoc(contractRef, { status: 'accepted' });
    setContracts(contracts.map(contract => contract.id === contractId ? { ...contract, status: 'accepted' } : contract));
  };

  const handleRejectContract = async (contractId) => {
    const contractRef = doc(db, 'contracts', contractId);
    await updateDoc(contractRef, { status: 'rejected' });
    setContracts(contracts.map(contract => contract.id === contractId ? { ...contract, status: 'rejected' } : contract));
  };

  const handleSignContract = async (contractId) => {
    const contractRef = doc(db, 'contracts', contractId);
    const signatureUrl = sigCanvasRef.current.toDataURL();
    await updateDoc(contractRef, { expertSignature: signatureUrl, signingDate: new Date() });
    setContracts(contracts.map(contract => contract.id === contractId ? { ...contract, expertSignature: signatureUrl, signingDate: new Date() } : contract));
    setSigningContractId(null);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleBackToDashboard = () => {
    navigate('/expertdashboard');
  };

  return (
    <div style={{ padding: '20px', animation: 'fadeIn 1s ease-in-out' }}>
      <ExpertNavbar />
      <div style={{
        backgroundImage: `url(${heroImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '50px 20px',
        textAlign: 'center',
        color: 'white',
        animation: 'fadeIn 1s ease-in-out'
      }}>
        <h1 style={{ fontSize: '3rem', marginBottom: '20px' }}>Welcome to the Expert Contracts Page</h1>
        <p style={{ fontSize: '1.2rem' }}>Manage your contracts efficiently and effectively.</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <button onClick={handleBackToDashboard} style={{
          padding: '10px 20px',
          marginTop: '40px',
          border: 'none',
          borderRadius: '10px',
          backgroundColor: '#007bff',
          color: '#fff',
          fontSize: '1rem',
          fontWeight: 'bold',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
          ':hover': { backgroundColor: '#0056b3' }
        }}>
          Back To Expert Dashboard
        </button>
      </div>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
        <button onClick={() => handleTabChange('shortlisted')} style={{
          padding: '10px 20px',
          border: 'none',
          borderRadius: '10px',
          backgroundColor: activeTab === 'shortlisted' ? '#007bff' : '#f8f9fa',
          color: activeTab === 'shortlisted' ? '#fff' : '#333',
          fontSize: '1rem',
          fontWeight: 'bold',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
          ':hover': { backgroundColor: '#0056b3', color: '#fff' }
        }}>Shortlisted</button>
        <button onClick={() => handleTabChange('interview')} style={{
          padding: '10px 20px',
          border: 'none',
          borderRadius: '10px',
          backgroundColor: activeTab === 'interview' ? '#007bff' : '#f8f9fa',
          color: activeTab === 'interview' ? '#fff' : '#333',
          fontSize: '1rem',
          fontWeight: 'bold',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
          ':hover': { backgroundColor: '#0056b3', color: '#fff' }
        }}>Interview</button>
        <button onClick={() => handleTabChange('rejected')} style={{
          padding: '10px 20px',
          border: 'none',
          borderRadius: '10px',
          backgroundColor: activeTab === 'rejected' ? '#007bff' : '#f8f9fa',
          color: activeTab === 'rejected' ? '#fff' : '#333',
          fontSize: '1rem',
          fontWeight: 'bold',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
          ':hover': { backgroundColor: '#0056b3', color: '#fff' }
        }}>Rejected</button>
        <button onClick={() => handleTabChange('contracts')} style={{
          padding: '10px 20px',
          border: 'none',
          borderRadius: '10px',
          backgroundColor: activeTab === 'contracts' ? '#007bff' : '#f8f9fa',
          color: activeTab === 'contracts' ? '#fff' : '#333',
          fontSize: '1rem',
          fontWeight: 'bold',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
          ':hover': { backgroundColor: '#0056b3', color: '#fff' }
        }}>Contracts</button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        {activeTab === 'shortlisted' && (
          <div>
            <h2 style={{ fontSize: '1.5rem', color: '#007bff', marginBottom: '10px' }}>Shortlisted</h2>
            {stages.filter(stage => stage.stage === 'shortlisted').length > 0 ? (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {stages.filter(stage => stage.stage === 'shortlisted').map((stage) => (
                  <div key={stage.id} style={{
                    background: '#fff',
                    padding: '20px',
                    borderRadius: '15px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    transition: 'box-shadow 0.3s',
                    ':hover': { boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)' }
                  }}>
                    <h4 style={{ fontSize: '1.2rem', color: '#333', marginBottom: '10px' }}>{stage.jobTitle}</h4>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faBriefcase} /> <strong>Job Title:</strong> {stage.jobTitle}
                      </p>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faEnvelope} /> <strong>Email:</strong> {stage.email}
                      </p>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faClock} /> <strong>Applied Date:</strong> {stage.createdAt ? new Date(stage.createdAt.seconds * 1000).toLocaleDateString() : 'N/A'}
                      </p>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faInfoCircle} /> <strong>Stage:</strong> {stage.stage}
                      </p>
                    </div>
                    <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                      <div style={{
                        padding: '10px 20px',
                        borderRadius: '15px',
                        backgroundColor: stage.stage === 'screening' ? '#007bff' : '#f8f9fa',
                        color: stage.stage === 'screening' ? '#fff' : '#333',
                        fontWeight: 'bold',
                        transition: 'background-color 0.3s, color 0.3s'
                      }}>Screening</div>
                      <div style={{
                        padding: '10px 20px',
                        borderRadius: '15px',
                        backgroundColor: stage.stage === 'interview' ? '#007bff' : '#f8f9fa',
                        color: stage.stage === 'interview' ? '#fff' : '#333',
                        fontWeight: 'bold',
                        transition: 'background-color 0.3s, color 0.3s'
                      }}>Interview</div>
                      <div style={{
                        padding: '10px 20px',
                        borderRadius: '15px',
                        backgroundColor: stage.stage === 'considered' ? '#007bff' : '#f8f9fa',
                        color: stage.stage === 'considered' ? '#fff' : '#333',
                        fontWeight: 'bold',
                        transition: 'background-color 0.3s, color 0.3s'
                      }}>Considered</div>
                      <div style={{
                        padding: '10px 20px',
                        borderRadius: '15px',
                        backgroundColor: stage.stage === 'offer' ? '#007bff' : '#f8f9fa',
                        color: stage.stage === 'offer' ? '#fff' : '#333',
                        fontWeight: 'bold',
                        transition: 'background-color 0.3s, color 0.3s'
                      }}>Offer</div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p style={{ textAlign: 'center', fontSize: '1rem', color: '#999' }}>No shortlisted jobs found.</p>
            )}
          </div>
        )}
        {activeTab === 'interview' && (
          <div>
            <h2 style={{ fontSize: '1.5rem', color: '#007bff', marginBottom: '10px' }}>Interview</h2>
            {stages.filter(stage => stage.stage === 'interview').length > 0 ? (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {stages.filter(stage => stage.stage === 'interview').map((stage) => (
                  <div key={stage.id} style={{
                    background: '#fff',
                    padding: '20px',
                    borderRadius: '15px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    transition: 'box-shadow 0.3s',
                    ':hover': { boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)' }
                  }}>
                    <h4 style={{ fontSize: '1.2rem', color: '#333', marginBottom: '10px' }}>{stage.jobTitle}</h4>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faBriefcase} /> <strong>Job Title:</strong> {stage.jobTitle}
                      </p>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faEnvelope} /> <strong>Email:</strong> {stage.email}
                      </p>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faClock} /> <strong>Applied Date:</strong> {stage.createdAt ? new Date(stage.createdAt.seconds * 1000).toLocaleDateString() : 'N/A'}
                      </p>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faInfoCircle} /> <strong>Stage:</strong> {stage.stage}
                      </p>
                    </div>
                    <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                      <div style={{
                        padding: '10px 20px',
                        borderRadius: '15px',
                        backgroundColor: stage.stage === 'screening' ? '#007bff' : '#f8f9fa',
                        color: stage.stage === 'screening' ? '#fff' : '#333',
                        fontWeight: 'bold',
                        transition: 'background-color 0.3s, color 0.3s'
                      }}>Screening</div>
                      <div style={{
                        padding: '10px 20px',
                        borderRadius: '15px',
                        backgroundColor: stage.stage === 'interview' ? '#007bff' : '#f8f9fa',
                        color: stage.stage === 'interview' ? '#fff' : '#333',
                        fontWeight: 'bold',
                        transition: 'background-color 0.3s, color 0.3s'
                      }}>Interview</div>
                      <div style={{
                        padding: '10px 20px',
                        borderRadius: '15px',
                        backgroundColor: stage.stage === 'considered' ? '#007bff' : '#f8f9fa',
                        color: stage.stage === 'considered' ? '#fff' : '#333',
                        fontWeight: 'bold',
                        transition: 'background-color 0.3s, color 0.3s'
                      }}>Considered</div>
                      <div style={{
                        padding: '10px 20px',
                        borderRadius: '15px',
                        backgroundColor: stage.stage === 'offer' ? '#007bff' : '#f8f9fa',
                        color: stage.stage === 'offer' ? '#fff' : '#333',
                        fontWeight: 'bold',
                        transition: 'background-color 0.3s, color 0.3s'
                      }}>Offer</div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p style={{ textAlign: 'center', fontSize: '1rem', color: '#999' }}>No interviews scheduled.</p>
            )}
          </div>
        )}
        {activeTab === 'rejected' && (
          <div>
            <h2 style={{ fontSize: '1.5rem', color: '#007bff', marginBottom: '10px' }}>Rejected</h2>
            {stages.filter(stage => stage.stage === 'rejected').length > 0 ? (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {stages.filter(stage => stage.stage === 'rejected').map((stage) => (
                  <div key={stage.id} style={{
                    background: '#fff',
                    padding: '20px',
                    borderRadius: '15px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    transition: 'box-shadow 0.3s',
                    ':hover': { boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)' }
                  }}>
                    <h4 style={{ fontSize: '1.2rem', color: '#333', marginBottom: '10px' }}>{stage.jobTitle}</h4>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faBriefcase} /> <strong>Job Title:</strong> {stage.jobTitle}
                      </p>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faEnvelope} /> <strong>Email:</strong> {stage.email}
                      </p>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faClock} /> <strong>Applied Date:</strong> {stage.createdAt ? new Date(stage.createdAt.seconds * 1000).toLocaleDateString() : 'N/A'}
                      </p>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faInfoCircle} /> <strong>Stage:</strong> {stage.stage}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p style={{ textAlign: 'center', fontSize: '1rem', color: '#999' }}>No rejected jobs found.</p>
            )}
          </div>
        )}
        {activeTab === 'contracts' && (
          <div>
            <h2 style={{ fontSize: '1.5rem', color: '#007bff', marginBottom: '10px' }}>Contracts</h2>
            {contracts.length > 0 ? (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {contracts.map((contract) => (
                  <div key={contract.id} style={{
                    background: '#fff',
                    padding: '20px',
                    borderRadius: '15px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    transition: 'box-shadow 0.3s',
                    ':hover': { boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)' }
                  }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '20px' }}>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faEnvelope} /> <strong>Client Email:</strong> {contract.clientEmail}
                      </p>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faEnvelope} /> <strong>Expert Email:</strong> {contract.expertEmail}
                      </p>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faBriefcase} /> <strong>Job Title:</strong> {contract.jobTitle}
                      </p>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faClock} /> <strong>Hire Date:</strong> {contract.createdAt ? new Date(contract.createdAt.seconds * 1000).toLocaleDateString() : 'N/A'}
                      </p>
                      <p style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <FontAwesomeIcon icon={faInfoCircle} /> <strong>Terms:</strong> <div style={{
                          maxHeight: '100px',
                          overflowY: 'scroll',
                          padding: '10px',
                          border: '1px solid #ccc',
                          borderRadius: '10px',
                          fontSize: '0.9rem'
                        }}>{contract.terms}</div>
                      </p>
                      {contract.expertSignature && (
                        <div style={{ fontSize: '1rem', color: '#333', display: 'flex', alignItems: 'center', gap: '10px' }}>
                          <PDFDownloadLink document={<ContractPDF contract={contract} />} fileName={`${contract.clientName}_Signed_Contract.pdf`}>
                            <button style={{
                              padding: '10px 20px',
                              border: 'none',
                              borderRadius: '10px',
                              backgroundColor: '#007bff',
                              color: '#fff',
                              fontSize: '1rem',
                              fontWeight: 'bold',
                              cursor: 'pointer',
                              transition: 'background-color 0.3s',
                              ':hover': { opacity: '0.8' }
                            }}>
                              <FontAwesomeIcon icon={faDownload} /> Download Signed Contract
                            </button>
                          </PDFDownloadLink>
                        </div>
                      )}
                      {!contract.expertSignature && <p style={{ color: 'red' }}>The Expert has not signed yet.</p>}
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <button onClick={() => setSigningContractId(contract.id)} style={{
                          padding: '10px 20px',
                          border: 'none',
                          borderRadius: '10px',
                          backgroundColor: '#007bff',
                          color: '#fff',
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          transition: 'background-color 0.3s',
                          ':hover': { opacity: '0.8' }
                        }}>
                          <FontAwesomeIcon icon={faSignature} /> Sign Contract
                        </button>
                        <button onClick={() => handleAcceptContract(contract.id)} style={{
                          padding: '10px 20px',
                          border: 'none',
                          borderRadius: '10px',
                          backgroundColor: '#28a745',
                          color: '#fff',
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          transition: 'background-color 0.3s',
                          ':hover': { opacity: '0.8' }
                        }}>
                          <FontAwesomeIcon icon={faCheck} /> Accept
                        </button>
                        <button onClick={() => handleRejectContract(contract.id)} style={{
                          padding: '10px 20px',
                          border: 'none',
                          borderRadius: '10px',
                          backgroundColor: '#ff6b6b',
                          color: '#fff',
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          transition: 'background-color 0.3s',
                          ':hover': { opacity: '0.8' }
                        }}>
                          <FontAwesomeIcon icon={faTimes} /> Reject
                        </button>
                        <PDFDownloadLink document={<ContractPDF contract={contract} />} fileName={`${contract.clientName}_Contract.pdf`}>
                          <button style={{
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '10px',
                            backgroundColor: '#007bff',
                            color: '#fff',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                            ':hover': { opacity: '0.8' }
                          }}>
                            <FontAwesomeIcon icon={faDownload} /> Download
                          </button>
                        </PDFDownloadLink>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p style={{ textAlign: 'center', fontSize: '1rem', color: '#999' }}>No contracts found.</p>
            )}
          </div>
        )}
      </div>
      {signingContractId && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          zIndex: '1000',
          maxWidth: '500px',
          width: '100%'
        }}>
          <SignatureCanvas
            ref={sigCanvasRef}
            penColor="black"
            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
            onEnd={() => setSignature(sigCanvasRef.current.toDataURL())}
          />
          <div style={{ display: 'flex', gap: '10px' }}>
            <button onClick={() => handleSignContract(signingContractId)} style={{
              padding: '10px 20px',
              border: 'none',
              borderRadius: '10px',
              backgroundColor: '#007bff',
              color: '#fff',
              fontSize: '1rem',
              fontWeight: 'bold',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
              ':hover': { opacity: '0.8' }
            }}>Save Signature</button>
            <button onClick={() => setSigningContractId(null)} style={{
              padding: '10px 20px',
              border: 'none',
              borderRadius: '10px',
              backgroundColor: '#ff6b6b',
              color: '#fff',
              fontSize: '1rem',
              fontWeight: 'bold',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
              ':hover': { opacity: '0.8' }
            }}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

const ContractPDF = ({ contract }) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>Contract Agreement</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Client Email:</Text>
        <Text>{contract.clientEmail}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Expert Email:</Text>
        <Text>{contract.expertEmail}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Job Title:</Text>
        <Text>{contract.jobTitle}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Hire Date:</Text>
        <Text>{contract.createdAt ? new Date(contract.createdAt.seconds * 1000).toLocaleDateString() : 'N/A'}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Terms:</Text>
        <Text>{contract.terms}</Text>
      </View>
      <View style={styles.signatureSection}>
        <Text style={styles.signatureLabel}>Client Signature:</Text>
        <Text style={styles.signature}>{contract.clientName}</Text>
      </View>
      {contract.expertSignature && (
        <View style={styles.signatureSection}>
          <Text style={styles.signatureLabel}>Expert Signature:</Text>
          <Image style={styles.signatureImage} src={contract.expertSignature} />
        </View>
      )}
      {contract.signingDate && (
        <View style={styles.section}>
          <Text style={styles.label}>Signing Date:</Text>
          <Text>{new Date(contract.signingDate).toLocaleDateString()}</Text>
        </View>
      )}
      {contract.signingTime && (
        <View style={styles.section}>
          <Text style={styles.label}>Signing Time:</Text>
          <Text>{new Date(contract.signingTime).toLocaleTimeString()}</Text>
        </View>
      )}
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'Helvetica',
  },
  header: {
    textAlign: 'center',
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  section: {
    marginBottom: 10,
  },
  label: {
    fontWeight: 'bold',
  },
  signatureSection: {
    marginTop: 20,
  },
  signatureLabel: {
    fontWeight: 'bold',
  },
  signature: {
    fontStyle: 'italic',
  },
  signatureImage: {
    width: 200,
    height: 100,
  },
});

export default ExpertContract;
