import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, googleAuthProvider } from '../firebase';
import ProfLogo from '../assets/images/login.jpg';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the root element for accessibility

const ExpertLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleLoginWithEmail = async () => {
    if (!termsAccepted) {
      alert('You must accept the terms and conditions to continue.');
      return;
    }
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/expertdashboard');
    } catch (error) {
      alert('Failed to log in: ' + error.message);
    }
  };

  const handleLoginWithGoogle = async () => {
    if (!termsAccepted) {
      alert('You must accept the terms and conditions to continue.');
      return;
    }
    try {
      await signInWithPopup(auth, googleAuthProvider);
      navigate('/expertdashboard');
    } catch (error) {
      alert('Failed to log in with Google: ' + error.message);
    }
  };

  const handleResetPassword = () => {
    navigate('/reset-password');
  };

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <div className="container" style={{
      display: 'flex',
      flexDirection: 'row', // Ensures side by side layout on desktop
      minHeight: '100vh',
      '@media (max-width: 768px)': {
        flexDirection: 'column' // Stack vertically on mobile
      }
    }}>
      <div className="image-side" style={{
        backgroundImage: `url(${ProfLogo})`,
        backgroundSize: 'cover',
        backgroundPosition: '',
        flex: 1,
        display: 'flex',
        '@media (max-width: 768px)': {
          display: 'none' // Hide the image on mobile
        }
      }}></div>
      <div className="form-side" style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 20px',
        maxWidth: '450px',
        width: '100%', // Full width on mobile
        '@media (min-width: 769px)': {
          maxWidth: '450px'
        }
      }}>
        <div style={{
          width: '100%',
          padding: '20px',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
        }}>
          <h2 style={{ marginBottom: '20px' }}>Welcome Back!</h2>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              width: '100%',
              padding: '12px 20px',
              marginBottom: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              width: '100%',
              padding: '12px 20px',
              marginBottom: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          />
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px'
          }}>
            <label style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
                style={{ marginRight: '10px' }}
              />
              Accept Terms and Conditions
            </label>
            <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={toggleModal}>
              View Terms
            </span>
          </div>
          <button
            onClick={handleLoginWithEmail}
            style={{
              width: '100%',
              padding: '12px 0',
              marginBottom: '10px',
              borderRadius: '4px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              cursor: 'pointer'
            }}
          >
            Log In
          </button>
          <button
            onClick={handleLoginWithGoogle}
            style={{
              width: '100%',
              padding: '12px 0',
              marginBottom: '20px',
              borderRadius: '4px',
              backgroundColor: '#db4437',
              color: 'white',
              border: 'none',
              cursor: 'pointer'
            }}
          >
            Continue with Google
          </button>
          <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={handleResetPassword}>
            Forgot password?
          </span>
          <br></br>
          <span style={{ color: '#007bff', cursor: 'pointer', marginTop: '10px' }} onClick={() => navigate('/register')}>
            Don't have an account? Sign up
          </span>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        contentLabel="Terms and Conditions"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '600px',
          }
        }}
      >
        <h2>Terms and Conditions</h2>
        <p>Here is a sample of terms and conditions. Please read and accept to continue.</p>
        <button onClick={toggleModal} style={{ padding: '10px 20px', borderRadius: '4px', backgroundColor: '#007bff', color: 'white', border: 'none', cursor: 'pointer' }}>Close</button>
      </Modal>
    </div>
  );
};

export default ExpertLogin;
