import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaFileAlt, FaHeart, FaExclamationCircle, FaEye, FaBars, FaBuilding, FaUserCircle, FaIndustry, FaFileContract } from 'react-icons/fa';
import { auth, db } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import styled from 'styled-components';

const SidebarContainer = styled.div`
  width: 250px;
  height: 100vh;
  background-color: #f8f9fa;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1050;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: ${props => props.isOpen ? '0' : '-100%'};
    transition: left 0.3s ease-in-out;
  }
`;

const ToggleButton = styled.button`
  display: none;
  position: fixed;
  top: 20px;
  left: ${props => props.isOpen ? 'calc(100% - 60px)' : '10px'};
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1051;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const Overlay = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1049;
`;

const MenuOption = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  margin: 5px 0;
  background-color: #e9ecef;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #dee2e6;
  }
`;

const Header = styled.div`
  font-weight: 600;
  color: #5c6f7c;
  font-size: 16px;
  padding-bottom: 10px;
  border-bottom: 2px solid #eff3f6;
  margin-bottom: 1rem;
`;

function ExpertSidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [profileCompletion, setProfileCompletion] = useState(0);
  const [applicationsCount, setApplicationsCount] = useState(0);
  const [savedJobsCount, setSavedJobsCount] = useState(0);
  const [contractsCount, setContractsCount] = useState(0);

  useEffect(() => {
    if (!user) return;

    const fetchProfileCompletion = async () => {
      const profileRef = doc(db, 'profiles', user.uid);
      const docSnap = await getDoc(profileRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const fields = ['certifications', 'title', 'country', 'currentJobTitle', 'cvUrl', 'email', 'gender', 'hourlyRate', 'linkedin', 'name', 'personalInviteLink', 'primarySkills', 'secondarySkills', 'timezone', 'videoIntroUrl', 'workExperience'];
        let filled = fields.reduce((acc, field) => acc + (data[field] ? 1 : 0), 0);
        setProfileCompletion(Math.round((filled / fields.length) * 100));
      }
    };

    const fetchCounts = async () => {
      if (!user) return;
      const applicationsQuery = query(collection(db, 'jobApplications'), where('email', '==', user.email));
      const savedJobsQuery = query(collection(db, 'users', user.uid, 'savedJobs'));
      const contractsQuery = query(collection(db, 'contracts'), where('expertEmail', '==', user.email));
      
      const [applicationsSnapshot, savedJobsSnapshot, contractsSnapshot] = await Promise.all([
        getDocs(applicationsQuery),
        getDocs(savedJobsQuery),
        getDocs(contractsQuery)
      ]);

      setApplicationsCount(applicationsSnapshot.size);
      setSavedJobsCount(savedJobsSnapshot.size);
      setContractsCount(contractsSnapshot.size);
    };

    fetchProfileCompletion();
    fetchCounts();

    const handleSavedJobsCountUpdate = (event) => {
      setSavedJobsCount(event.detail.count);
    };

    window.addEventListener('updateSavedJobsCount', handleSavedJobsCountUpdate);

    return () => {
      window.removeEventListener('updateSavedJobsCount', handleSavedJobsCountUpdate);
    };
  }, [user]);

  return (
    <>
      <SidebarContainer isOpen={isSidebarOpen}>
        <Header>Navigation</Header>
        <MenuOption onClick={() => navigate(`/candidate-profile-edit/${user?.email}`)}>
          <FaUserCircle style={{ marginRight: '10px' }} /> View Your Profile
        </MenuOption>
        <MenuOption onClick={() => window.location.href = 'https://expertsbloomresumebuilder.vercel.app/resume-import'}>
          <FaFileAlt style={{ marginRight: '10px' }} /> Resume Builder
        </MenuOption>
        <MenuOption onClick={() => navigate('/vacancies')}>
          <FaBuilding style={{ marginRight: '10px' }} /> Vacancies
        </MenuOption>
        <MenuOption onClick={() => navigate('/companies')}>
          <FaIndustry style={{ marginRight: '10px' }} /> Companies
        </MenuOption>
        <Header>Job Actions</Header>
        <MenuOption onClick={() => navigate('/myjobs')}>
          <FaEye style={{ marginRight: '10px' }} /> You have applied to <span style={{ marginLeft: 'auto', backgroundColor: 'red', borderRadius: '15px', color: 'white', padding: '0.25rem 0.6rem', fontSize: '0.675rem', fontWeight: 'bold' }}>{applicationsCount}</span> jobs
        </MenuOption>
        <MenuOption onClick={() => navigate('/savedjobs')}>
          <FaHeart style={{ marginRight: '10px' }} /> Saved Jobs <span style={{ marginLeft: 'auto', backgroundColor: 'red', borderRadius: '15px', color: 'white', padding: '0.25rem 0.6rem', fontSize: '0.675rem', fontWeight: 'bold' }}>{savedJobsCount}</span>
        </MenuOption>
        <MenuOption onClick={() => navigate('/expertcontract')}>
          <FaFileContract style={{ marginRight: '10px' }} /> My Contracts <span style={{ marginLeft: 'auto', backgroundColor: 'red', borderRadius: '15px', color: 'white', padding: '0.25rem 0.6rem', fontSize: '0.675rem', fontWeight: 'bold' }}>{contractsCount}</span>
        </MenuOption>
        <Header>Notifications</Header>
        <MenuOption>
          <FaExclamationCircle style={{ marginRight: '10px' }} />
          Your Profile is {profileCompletion}% complete
        </MenuOption>
        <a href="/editprofile" style={{ display: 'block', marginTop: '10px', textAlign: 'center', backgroundColor: '#007bff', color: 'white', padding: '8px', borderRadius: '15px', textDecoration: 'none' }}>
          Edit Your Profile
        </a>
      </SidebarContainer>
      <Overlay isOpen={isSidebarOpen} onClick={() => setIsSidebarOpen(false)} />
      <ToggleButton isOpen={isSidebarOpen} onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        <FaBars />
      </ToggleButton>
    </>
  );
}

export default ExpertSidebar;
