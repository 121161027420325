import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, setDoc, getDocs, collection, query, where, limit, startAfter } from 'firebase/firestore';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock, faBriefcase, faHeart, faEye, faBrain, faMapMarkedAlt, faDollarSign,
  faCalendarAlt, faGraduationCap, faGlobe, faIndustry, faQuestionCircle,
  faLayerGroup, faBuilding, faFlag, faTimes, faThumbsUp, faList
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import ExpertSidebar from './expertsidebar';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import ExpertNavbar from './expertnavbar';
import { auth, db } from '../firebase';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f9fa;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const JobSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const JobList = styled.div`
  flex: 1;
  padding-right: 0;
  overflow-y: auto;
  max-height: 80vh;

  @media (min-width: 768px) {
    padding-right: 20px;
  }
`;

const JobDetails = styled.div`
  flex: 2;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 20px;
  display: ${props => props.show ? 'block' : 'none'};

  @media (min-width: 768px) {
    display: block;
  }
`;

const JobSectionHeader = styled.div`
  font-size: 1.75rem;
  color: #333;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const SearchInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 10px;
  width: 200px;
  font-size: 14px;

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
  }
`;

const FilterDropdown = styled.select`
  padding: 10px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 14px;

  @media (max-width: 767px) {
    margin-left: 0;
    width: 100%;
  }
`;

const JobCard = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0 6px 12px rgba(0,0,0,0.2);
  }
`;

const JobHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #f8f9fa;
`;

const JobImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  object-fit: contain;
  margin-right: 20px;
  background: white;

  @media (max-width: 767px) {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
`;

const JobTitleContainer = styled.div`
  flex: 1;
`;

const JobTitle = styled.h3`
  color: #333;
  font-size: 18px;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const JobCompany = styled.p`
  color: #6c757d;
  margin: 5px 0 0;
  font-size: 14px;
`;

const JobInfo = styled.div`
  padding: 15px;
  text-align: left;
  color: #6c757d;

  @media (max-width: 767px) {
    padding: 10px;
  }
`;

const JobDetail = styled.p`
  margin: 5px 0;
  font-size: 14px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    color: #007bff;
  }
`;

const JobDescription = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: #6c757d;
`;

const JobActions = styled.div`
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f9fa;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const JobAction = styled.button`
  background: white;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    background-color: #007bff;
    color: white;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const LoadMoreButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 20px auto;
  display: block;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: darken(#007bff, 10%);
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const JobDetailsPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-height: 80vh;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 20px;
  overflow-y: auto;
  z-index: 1000;
  display: ${props => props.show ? 'block' : 'none'};

  @media (min-width: 768px) {
    display: none;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ToggleButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
`;

const ToggleButton = styled.button`
  background: ${props => props.active ? '#007bff' : 'white'};
  color: ${props => props.active ? 'white' : '#007bff'};
  border: 1px solid #007bff;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    background-color: ${props => props.active ? '#0056b3' : '#007bff'};
    color: white;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
  }
`;

const ExpertDashboard = () => {
  const [user, loading] = useAuthState(auth);
  const [jobs, setJobs] = useState([]);
  const [recommendedJobs, setRecommendedJobs] = useState([]);
  const [aiRecommendedJobs, setAIRecommendedJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterIndustry, setFilterIndustry] = useState('');
  const [showRecommended, setShowRecommended] = useState(false);
  const [showAIRecommended, setShowAIRecommended] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchUserProfile(user.email);
    }
  }, [user]);

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [jobs, recommendedJobs, aiRecommendedJobs, searchTerm, filterIndustry, showRecommended, showAIRecommended]);

  const fetchUserProfile = async (email) => {
    const userProfileQuery = query(collection(db, 'profiles'), where('email', '==', email));
    const userProfileSnapshot = await getDocs(userProfileQuery);
    const userProfile = userProfileSnapshot.docs[0]?.data();
    if (userProfile?.industry) {
      fetchRecommendedJobs(userProfile.industry);
      fetchAIRecommendedJobs(userProfile);
    }
  };

  const fetchJobs = async () => {
    const jobsQuery = query(collection(db, "jobs"), limit(10));
    const querySnapshot = await getDocs(jobsQuery);
    const jobsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setJobs(sortJobsByInfo(jobsData));
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    if (jobsData.length > 0) {
      setSelectedJob(jobsData[0]);
    }
  };

  const fetchMoreJobs = async () => {
    if (lastVisible && !loading) {
      const moreJobsQuery = query(collection(db, "jobs"), startAfter(lastVisible), limit(10));
      const querySnapshot = await getDocs(moreJobsQuery);
      const moreJobsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobs(sortJobsByInfo([...jobs, ...moreJobsData]));
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    }
  };

  const fetchRecommendedJobs = async (industry) => {
    try {
      const similarIndustries = getSimilarIndustries(industry);
      const recommendedJobsQuery = query(collection(db, 'jobs'), where('industry', 'in', similarIndustries), limit(10));
      const recommendedJobsSnapshot = await getDocs(recommendedJobsQuery);
      const recommendedJobsData = recommendedJobsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setRecommendedJobs(recommendedJobsData);
    } catch (error) {
      console.error("Error fetching recommended jobs:", error);
    }
  };

  const fetchAIRecommendedJobs = async (userProfile) => {
    const allJobsSnapshot = await getDocs(collection(db, 'jobs'));
    const jobsData = allJobsSnapshot.docs.map(doc => ({ id: doc.id, title: doc.data().title, description: doc.data().description, industry: doc.data().industry }));

    const prompt = `Based on the user's profile, recommend jobs from the following list that might be a good fit. Here is the user's profile information in JSON format: ${JSON.stringify(userProfile)}. Here is the list of available jobs: ${JSON.stringify(jobsData)}.`;

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: "You are a helpful assistant."
            },
            {
              role: "user",
              content: prompt
            }
          ],
          max_tokens: 200,
          n: 1,
          stop: null,
          temperature: 0.5,
        },
        {
          headers: {
            'Authorization': `Bearer sk-None-cKKUvLMKNBQp0IlnfCr2T3BlbkFJ3VME98HSdkFPkoSMkDy7`,
            'Content-Type': 'application/json',
          },
        }
      );

      const result = response.data.choices[0].message.content.trim();
      const aiJobTitles = result.split('\n').map(line => {
        const parts = line.split(':');
        if (parts.length >= 2) {
          return parts[0].replace(/^\d+\.\s*/, '').trim();
        }
        return null;
      }).filter(title => title !== null);

      const cleanText = (text) => {
        return text ? text.replace(/[^a-zA-Z ]/g, '').toLowerCase() : '';
      };

      const detailedAIJobs = jobsData.filter(job => {
        const cleanedTitle = cleanText(job.title);
        const cleanedDescription = cleanText(job.description).slice(0, 50);
        return aiJobTitles.some(aiTitle => {
          const cleanedAITitle = cleanText(aiTitle);
          return cleanedTitle.includes(cleanedAITitle) || cleanedDescription.includes(cleanedAITitle);
        });
      });

      setAIRecommendedJobs(detailedAIJobs);
    } catch (error) {
      console.error("Error fetching AI recommended jobs:", error);
      setAIRecommendedJobs([]);
    }
  };

  const getSimilarIndustries = (industry) => {
    const industryMap = {
      "IT": ["IT", "Software Development", "Fullstack Development", "Web Development"],
      "Software Development": ["Software Development", "IT", "Fullstack Development", "Web Development"],
      "Fullstack Development": ["Fullstack Development", "IT", "Software Development", "Web Development"],
      "Marketing": ["Marketing", "Sales", "Advertising"],
      "Finance": ["Finance", "Accounting", "Banking"]
      // Add more mappings as necessary
    };
    return industryMap[industry] || [industry];
  };

  const sortJobsByInfo = (jobsData) => {
    return jobsData.sort((a, b) => {
      const aInfoCount = Object.values(a).filter(val => val && typeof val !== 'object').length;
      const bInfoCount = Object.values(b).filter(val => val && typeof val !== 'object').length;
      return bInfoCount - aInfoCount;
    });
  };

  const applyFilters = () => {
    let filtered = (showAIRecommended ? aiRecommendedJobs : (showRecommended ? recommendedJobs : jobs)).filter(job => {
      const jobTitle = job.title ? job.title.toLowerCase() : '';
      const industry = job.industry ? job.industry.toLowerCase() : '';
      return jobTitle.includes(searchTerm.toLowerCase()) &&
             (!filterIndustry || industry === filterIndustry.toLowerCase());
    });
    setFilteredJobs(filtered);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleIndustryChange = (e) => {
    setFilterIndustry(e.target.value);
  };

  const saveJob = async (jobId) => {
    if (user) {
      const jobDocRef = doc(db, "users", user.uid, "savedJobs", jobId);
      await setDoc(jobDocRef, { jobId });
    }
  };

  const applyForJob = (job) => {
    navigate('/job-apply', { state: { jobDetails: job } });
  };

  const viewJobDetails = (job) => {
    setSelectedJob(job);
    if (window.innerWidth < 768) {
      setShowPopup(true);
    }
  };

  const closeJobDetails = () => {
    setSelectedJob(null);
    setShowPopup(false);
  };

  return (
    <>
      <ExpertNavbar />
      <Container>
        <ExpertSidebar />
        <MainContent>
          <ToggleButtons>
            <ToggleButton
              active={!showRecommended && !showAIRecommended}
              onClick={() => { setShowRecommended(false); setShowAIRecommended(false); }}
            >
              <FontAwesomeIcon icon={faList} /> Available Jobs ({jobs.length})
            </ToggleButton>
            <ToggleButton
              active={showRecommended && !showAIRecommended}
              onClick={() => { setShowRecommended(true); setShowAIRecommended(false); }}
            >
              <FontAwesomeIcon icon={faThumbsUp} /> Recommended Jobs ({recommendedJobs.length})
            </ToggleButton>
            <ToggleButton
              active={showAIRecommended}
              onClick={() => { setShowAIRecommended(true); setShowRecommended(false); }}
            >
              <FontAwesomeIcon icon={faBrain} /> AI Recommended Jobs ({aiRecommendedJobs.length})
            </ToggleButton>
          </ToggleButtons>
          <JobSectionHeader>
            <span>{showAIRecommended ? 'AI Recommended Jobs' : (showRecommended ? 'Recommended Jobs' : 'Available Jobs')}</span>
            <div>
              <SearchInput
                type="text"
                placeholder="Search job titles..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <FilterDropdown onChange={handleIndustryChange} value={filterIndustry}>
                <option value="">All Industries</option>
                <option value="marketing">Marketing</option>
                <option value="fullstack_engineer">Fullstack Engineer</option>
                <option value="data_science">Data Science</option>
                <option value="product_management">Product Management</option>
                <option value="graphic_design">Graphic Design</option>
                <option value="ui_ux_design">UI/UX Design</option>
                <option value="software_development">Software Development</option>
                <option value="project_management">Project Management</option>
                <option value="sales">Sales</option>
                <option value="customer_support">Customer Support</option>
                <option value="human_resources">Human Resources</option>
                <option value="finance">Finance</option>
                <option value="legal">Legal</option>
                <option value="education">Education</option>
                <option value="healthcare">Healthcare</option>
                <option value="real_estate">Real Estate</option>
                <option value="logistics">Logistics</option>
                <option value="manufacturing">Manufacturing</option>
                <option value="hospitality">Hospitality</option>
                <option value="other">Other</option>
              </FilterDropdown>
            </div>
          </JobSectionHeader>
          <JobSection>
            <JobList>
              {filteredJobs.map((job, index) => (
                <JobCard key={job.id || index} onClick={() => viewJobDetails(job)}>
                  <JobHeader>
                    <JobImage src={job.logo || 'https://via.placeholder.com/150'} alt="Company Logo" />
                    <JobTitleContainer>
                      <JobTitle>{job.title}</JobTitle>
                      <JobCompany>{job.companyName}</JobCompany>
                    </JobTitleContainer>
                  </JobHeader>
                  <JobInfo>
                    <JobDetail><FontAwesomeIcon icon={faClock} /> {job.experience}</JobDetail>
                    <JobDetail><FontAwesomeIcon icon={faBriefcase} /> {job.type}</JobDetail>
                    <JobDetail><FontAwesomeIcon icon={faBuilding} /> {job.companyName}</JobDetail>
                    <JobDetail><FontAwesomeIcon icon={faMapMarkedAlt} /> {job.address}</JobDetail>
                    <JobDetail><FontAwesomeIcon icon={faIndustry} /> {job.industry}</JobDetail>
                    <JobDetail><FontAwesomeIcon icon={faDollarSign} /> ${job.minSalary} - ${job.maxSalary} {job.salaryType}</JobDetail>
                    <JobDetail><FontAwesomeIcon icon={faFlag} /> {job.country}</JobDetail>
                    <JobDescription>{job.description}</JobDescription>
                  </JobInfo>
                </JobCard>
              ))}
              {filteredJobs.length >= 10 && (
                <LoadMoreButton onClick={fetchMoreJobs} disabled={loading}>Load More</LoadMoreButton>
              )}
            </JobList>
            {selectedJob && (
              <JobDetails show={selectedJob !== null && window.innerWidth >= 768}>
                <img src={selectedJob.logo} alt="Company Logo" style={{ width: '100px', height: '100px', margin: '20px auto' }} />
                <h2 style={{ color: '#333', fontSize: '1.5rem' }}>{selectedJob.title} - {selectedJob.companyName}</h2>
                <p><FontAwesomeIcon icon={faBriefcase} /> Category: {selectedJob.industry}</p>
                <p><FontAwesomeIcon icon={faBuilding} /> Company: {selectedJob.companyName}</p>
                <p><FontAwesomeIcon icon={faFlag} /> Country: {selectedJob.country}</p>
                <p><FontAwesomeIcon icon={faGlobe} /> Website: <a href={selectedJob.website} target="_blank" rel="noopener noreferrer">{selectedJob.website}</a></p>
                <p><FontAwesomeIcon icon={faIndustry} /> Industry: {selectedJob.industry}</p>
                <p><FontAwesomeIcon icon={faLayerGroup} /> Skills: {selectedJob.skills}</p>
                <p><FontAwesomeIcon icon={faGraduationCap} /> Qualifications: {selectedJob.qualifications}</p>
                <p><FontAwesomeIcon icon={faCalendarAlt} /> Expiry Date: {selectedJob.expiryDate}</p>
                <p><FontAwesomeIcon icon={faClock} /> Experience: {selectedJob.experience}</p>
                <p><FontAwesomeIcon icon={faDollarSign} /> Salary Range: ${selectedJob.minSalary} - ${selectedJob.maxSalary} {selectedJob.salaryType}</p>
                <p><FontAwesomeIcon icon={faQuestionCircle} /> Questions: {selectedJob.questions.join(', ')}</p>
                <p><FontAwesomeIcon icon={faMapMarkedAlt} /> Address: {selectedJob.address}</p>
                <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Description:</p>
                <p>{selectedJob.description}</p>
                <JobActions>
                  <JobAction onClick={() => saveJob(selectedJob.id)}><FontAwesomeIcon icon={faHeart} /> Save</JobAction>
                  <JobAction onClick={() => applyForJob(selectedJob)}><FontAwesomeIcon icon={faThumbsUp} /> Apply</JobAction>
                </JobActions>
              </JobDetails>
            )}
          </JobSection>
          <JobDetailsPopup show={showPopup}>
            {selectedJob && (
              <>
                <CloseButton onClick={closeJobDetails}><FontAwesomeIcon icon={faTimes} /></CloseButton>
                <img src={selectedJob.logo} alt="Company Logo" style={{ width: '100px', height: '100px', margin: '20px auto' }} />
                <h2 style={{ color: '#333', fontSize: '1.5rem' }}>{selectedJob.title} - {selectedJob.companyName}</h2>
                <p><FontAwesomeIcon icon={faBriefcase} /> Category: {selectedJob.industry}</p>
                <p><FontAwesomeIcon icon={faBuilding} /> Company: {selectedJob.companyName}</p>
                <p><FontAwesomeIcon icon={faFlag} /> Country: {selectedJob.country}</p>
                <p><FontAwesomeIcon icon={faGlobe} /> Website: <a href={selectedJob.website} target="_blank" rel="noopener noreferrer">{selectedJob.website}</a></p>
                <p><FontAwesomeIcon icon={faIndustry} /> Industry: {selectedJob.industry}</p>
                <p><FontAwesomeIcon icon={faLayerGroup} /> Skills: {selectedJob.skills}</p>
                <p><FontAwesomeIcon icon={faGraduationCap} /> Qualifications: {selectedJob.qualifications}</p>
                <p><FontAwesomeIcon icon={faCalendarAlt} /> Expiry Date: {selectedJob.expiryDate}</p>
                <p><FontAwesomeIcon icon={faClock} /> Experience: {selectedJob.experience}</p>
                <p><FontAwesomeIcon icon={faDollarSign} /> Salary Range: ${selectedJob.minSalary} - ${selectedJob.maxSalary} {selectedJob.salaryType}</p>
                <p><FontAwesomeIcon icon={faQuestionCircle} /> Questions: {selectedJob.questions.join(', ')}</p>
                <p><FontAwesomeIcon icon={faMapMarkedAlt} /> Address: {selectedJob.address}</p>
                <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Description:</p>
                <p>{selectedJob.description}</p>
                <JobActions>
                  <JobAction onClick={() => saveJob(selectedJob.id)}><FontAwesomeIcon icon={faHeart} /> Save</JobAction>
                  <JobAction onClick={() => applyForJob(selectedJob)}><FontAwesomeIcon icon={faThumbsUp} /> Apply</JobAction>
                </JobActions>
              </>
            )}
          </JobDetailsPopup>
        </MainContent>
      </Container>
      <ScrollTop />
      <Footer />
    </>
  );
};

export default ExpertDashboard;
