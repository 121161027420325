import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { auth, googleAuthProvider } from '../firebase';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import Modal from 'react-modal';
import styled from 'styled-components';

Modal.setAppElement('#root');

const ClientLoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        await checkUserSubscription(firebaseUser);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, [navigate, db]);

  const checkUserSubscription = async (firebaseUser) => {
    const userRef = doc(db, 'users', firebaseUser.uid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const userPlan = userDoc.data().plan;
      if (userPlan) {
        navigate('/clientdashboard');
      } else {
        navigate('/pricing');
      }
    } else {
      navigate('/pricing');
    }
  };

  const handleLoginWithEmail = async () => {
    if (!termsAccepted) {
      alert('You must accept the terms and conditions to continue.');
      return;
    }
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await validateUserRole(user, 'client');
    } catch (error) {
      alert('Failed to log in: ' + error.message);
    }
  };

  const handleLoginWithGoogle = async () => {
    if (!termsAccepted) {
      alert('Please accept the terms and conditions to continue.');
      return;
    }
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      const user = result.user;
      await validateUserRole(user, 'client');
    } catch (error) {
      alert('Failed to log in with Google: ' + error.message);
    }
  };

  const validateUserRole = async (user, expectedRole) => {
    const userRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(userRef);

    if (docSnap.exists() && docSnap.data().role !== expectedRole) {
      alert(`Access Denied: Your account does not have ${expectedRole} privileges.`);
      return;
    }

    if (!docSnap.exists() || docSnap.data().role !== expectedRole) {
      await setDoc(userRef, { email: user.email, role: expectedRole }, { merge: true });
    }

    await checkUserSubscription(user);
  };

  const handleResetPassword = () => {
    navigate('/reset-password');
  };

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <Container>
      <FormSide>
        <FormContainer>
          <Title>Welcome Back!</Title>
          <Description>Enter your details to log in to ExpertsBloom as a Client.</Description>
          <StyledInput type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <StyledInput type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <CheckboxContainer>
            <label>
              <Checkbox type="checkbox" checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} />
              Accept Terms and Conditions
            </label>
            <TermsLink onClick={toggleModal}>View Terms</TermsLink>
          </CheckboxContainer>
          <StyledButton onClick={handleLoginWithEmail}>Log In</StyledButton>
          <StyledButton onClick={handleLoginWithGoogle} style={{ backgroundColor: '#db4437' }}>Continue with Google</StyledButton>
          <LinkText onClick={handleResetPassword}>Forgot password?</LinkText>
          <LinkText onClick={() => navigate('/clientregister')}>Don't have an account? Sign up</LinkText>
        </FormContainer>
      </FormSide>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        contentLabel="Terms and Conditions"
        style={modalStyle}>
        <ModalTitle>Terms and Conditions</ModalTitle>
        <ModalContent>Here is a sample of terms and conditions. Please read and accept to continue.</ModalContent>
        <StyledButton onClick={toggleModal} style={{ backgroundColor: '#007bff' }}>Close</StyledButton>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f9;
`;

const FormSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  max-width: 450px;
`;

const FormContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: fadeIn 1.5s ease-in-out;
`;

const Title = styled.h2`
  font-family: 'Open Sans', sans-serif;
  color: #333;
  margin-bottom: 10px;
  animation: slideInFromLeft 1s ease-in-out;
`;

const Description = styled.p`
  font-family: 'Open Sans', sans-serif;
  color: #666;
  margin-bottom: 20px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const TermsLink = styled.span`
  cursor: pointer;
  color: #007bff;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 12px 0;
  margin-bottom: 10px;
  border-radius: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
`;

const LinkText = styled.span`
  cursor: pointer;
  color: #007bff;
  &:hover {
    text-decoration: underline;
  }
  margin-top: 10px;
`;

const ModalTitle = styled.h2`
  font-family: 'Open Sans', sans-serif;
  color: #333;
  margin-bottom: 10px;
`;

const ModalContent = styled.p`
  font-family: 'Open Sans', sans-serif;
  color: #666;
  margin-bottom: 20px;
`;

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '600px',
    borderRadius: '10px',
    padding: '20px'
  }
};

export default ClientLoginScreen;
